import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortRewardBoxReceivedPropertiesSchema = z.object({
  rewardBoxId: z.string().uuid(),
  campaignId: z.string().uuid().nullable(),
  campaignInternalName: z.string().nullable(),
  rewardConfigurationId: z.string().uuid(),
});
export type CohortRewardBoxReceivedProperties = z.infer<
  typeof CohortRewardBoxReceivedPropertiesSchema
>;

export type CohortRewardBoxReceivedEventStruct = UserEventStruct<
  'cohort.reward-box.received',
  keyof CohortRewardBoxReceivedProperties,
  CohortRewardBoxReceivedProperties,
  CohortRewardBoxReceivedProperties
>;

export const CohortRewardBoxReceivedEventSpec: UserEventSpec<CohortRewardBoxReceivedEventStruct> = {
  id: 'cohort.reward-box.received',
  name: 'Received a Mystery Box',
  description: 'Event Triggered when a user receives a new Mystery Box',
  propertiesSchema: CohortRewardBoxReceivedPropertiesSchema,
  exposedPropertiesSchema: CohortRewardBoxReceivedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
