import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';
import {Sizes} from '@cohort/shared/utils/media';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';

const MediaContentCohortVideo: React.FC<MediaContentProps<CohortVideoMediaStruct>> = ({
  description,
  config,
  title,
}) => (
  <div className="flex flex-col gap-y-6">
    {config && (
      <div className="relative aspect-video w-full">
        <VideoPlayer
          aspectRatio="aspect-video"
          thumbnailSrc={config.thumbnailFileKey}
          videoSrc={config.videoFileKey}
          style={{width: `${Sizes.M}px`}}
        />
      </div>
    )}

    <MediaContentInfos title={title} description={description} />
  </div>
);

export default MediaContentCohortVideo;
