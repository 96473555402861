import type {App} from '@cohort/wallet/apps';
import {Apps} from '@cohort/wallet/apps';
import OAuthErrorModal from '@cohort/wallet/components/modals/oauth/OAuthErrorModal';
import TestModeBanner from '@cohort/wallet/components/test-mode/TestModeBanner';
import useLazyRouteLoader from '@cohort/wallet/hooks/lazyRouteLoader';
import useOauthError from '@cohort/wallet/hooks/oAuthError';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {Outlet} from 'react-router-dom';

type BaseLayoutProps = {
  navbar?: JSX.Element;
  footer?: JSX.Element;
};
const BaseLayout: React.FC<BaseLayoutProps> = ({navbar, footer}) => {
  const embedded = useAppStore(store => store.embedded);
  const {oauthError, setOauthError, getErrorMessage} = useOauthError();
  const loader = useLazyRouteLoader();
  const app: App | undefined = Apps.find(
    app =>
      app.spec.userConnector?.id !== undefined &&
      app.spec.userConnector.id === oauthError?.connectorId
  );

  const errorMessage = getErrorMessage(app, oauthError);

  return (
    <div
      className="relative grid overflow-hidden [grid-template-rows:min-content_max-content_1fr_min-content]"
      style={{
        height: embedded ? window.innerHeight : '100svh',
        maxHeight: embedded ? '100%' : '100lvh',
        gridTemplateAreas: `
          "testModeBanner"
          "header"
          "main"
          "tabbar"
        `,
      }}
    >
      <TestModeBanner />
      {navbar}
      <div className="h-full overflow-auto">
        {loader}
        <Outlet />
        {oauthError && (
          <OAuthErrorModal
            title={errorMessage?.title}
            description={errorMessage?.description}
            onClose={() => setOauthError(undefined)}
          />
        )}
      </div>
      {footer}
    </div>
  );
};

export default BaseLayout;
