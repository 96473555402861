import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import {useEffect, useRef, useState} from 'react';
import {flushSync} from 'react-dom';
import {getI18n, useTranslation} from 'react-i18next';

type InstagramEmbedProps = {
  postUrl: string;
  width?: number | string;
};

declare global {
  interface Window {
    instgrm?: {
      Embeds: {
        process: () => void;
      };
    };
  }
}

const InstagramEmbedPost: React.FC<InstagramEmbedProps> = ({postUrl, width = 400}) => {
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.instagram.instagramEmbedPost',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });
  const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    loadScript('https://www.instagram.com/embed.js')
      .then(() => {
        if (window.instgrm) {
          flushSync(() => setIsLoading(false));
          window.instgrm.Embeds.process();
        } else {
          intervalRef.current = setInterval(() => {
            if (window.instgrm) {
              clearInterval(intervalRef.current);
              flushSync(() => setIsLoading(false));
              window.instgrm.Embeds.process();
            }
          }, 100);
        }
      })
      .catch(() => setIsError(true));

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (isLoading) {
    return <EmbedSkeleton width={width} />;
  }

  if (isError) {
    return <EmbedError error={t('instagramEmbedError')} />;
  }

  return (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={postUrl}
      data-instgrm-version="14"
      style={{width}}
    />
  );
};

export default InstagramEmbedPost;
