import type {Language} from '@cohort/shared/schema/common';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import {create} from 'zustand';

export type UserStore = {
  initEmail?: string;
  isAuthenticating: boolean;
  isLoggedIn: boolean;
  user: UserWDto | null;
  localizationLoaded: boolean;
  authenticated: boolean;
  login: (user: UserWDto, authenticated: boolean) => void;
  logout: () => void;
  setIsAuthenticating: (isAuthenticating: boolean) => void;
  setInitEmail: (email?: string) => void;
  setLocalizationLoaded: (localizationLoaded: boolean) => void;
  setUserLanguage: (language: Language) => void;
  setUserNotifications: (notificationsEnabled: boolean) => void;
  setUserHandle: (handle: string) => void;
  setAuthenticated: (authenticated: boolean) => void;
};

export const useUserStore = create<UserStore>(set => ({
  isAuthenticating: true,
  isLoggedIn: false,
  user: null,
  localizationLoaded: false,
  authenticated: false,
  login: (user, authenticated) =>
    set({
      isAuthenticating: false,
      isLoggedIn: true,
      user,
      authenticated,
    }),
  logout: () =>
    set({
      isAuthenticating: false,
      isLoggedIn: false,
      user: null,
      authenticated: false,
    }),
  setIsAuthenticating: isAuthenticating => set({isAuthenticating}),
  setInitEmail: email => set({initEmail: email}),
  setLocalizationLoaded: localizationLoaded => set({localizationLoaded}),
  setUserLanguage: lang =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          lang,
        },
      };
    }),
  setUserNotifications: notificationsEnabled =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          notificationsEnabled,
        },
      };
    }),
  setUserHandle: handle =>
    set(state => {
      if (!state.user) {
        return state;
      }
      return {
        user: {
          ...state.user,
          membershipPass: {
            ...state.user.membershipPass,
            memberHandle: handle,
          },
        },
      };
    }),
  setAuthenticated: authenticated => set({authenticated}),
}));
