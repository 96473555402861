import {urlTransformer} from '@cohort/shared/utils/validators';
import Spinner from '@cohort/shared-frontend/components/Spinner';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {Link} from '@phosphor-icons/react';
import {isString} from 'lodash';
import React from 'react';
import {isEmpty} from 'remeda';
import {match} from 'ts-pattern';

const DefaultUrlMetadataImage: React.FC = () => (
  <div className="flex h-full w-full items-center justify-center rounded-[--xps-card-border-radius] bg-slate-100">
    <Link className="text-slate-400" size={24} />
  </div>
);

type LinkPreviewCardProps = {
  urlData: {
    url: string | null;
    imageSrc: string | null;
    title: string | null;
  };
  loading?: boolean;
  error?: boolean;
  children?: React.ReactNode;
  className?: string;
};

/**
 * Displays the metadata of a given link (title, url, image).
 */
const LinkPreviewCard: React.FC<LinkPreviewCardProps> = ({
  children,
  className,
  error = false,
  loading = false,
  urlData,
}) => {
  const displayedImage = match({loading, error})
    .with({loading: true}, () => (
      <div className="flex h-full w-full items-center justify-center rounded-[--xps-card-border-radius] bg-slate-100">
        <Spinner size={24} />
      </div>
    ))
    .with({error: true}, () => <DefaultUrlMetadataImage />)
    .with({error: false}, () => {
      if (urlData.imageSrc === null) {
        return <DefaultUrlMetadataImage />;
      }

      return (
        <img
          src={urlData.imageSrc}
          alt="Link preview"
          className="h-20 w-20 rounded-[--xps-card-border-radius] object-cover"
        />
      );
    })
    .exhaustive();

  const displayedTitle = !loading && isString(urlData.title) ? urlData.title : '';

  const displayedUrl =
    isString(urlData.url) && !isEmpty(urlData.url) ? urlTransformer(urlData.url) : '';

  return (
    <a
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...(error && !isEmpty(displayedUrl) ? {href: displayedUrl} : {})}
      className={cn(
        'flex items-center rounded-[--xps-card-border-radius] border border-[--xps-border-color] p-4 !no-underline shadow-sm',
        error && !isEmpty(displayedUrl) ? 'cursor-pointer' : '!cursor-not-allowed',
        className
      )}
    >
      <div className="mr-4 flex-shrink-0">
        <div className="aspect-square h-20 w-20">{displayedImage}</div>
      </div>
      <div className="min-w-0 flex-grow overflow-hidden">
        {displayedTitle && (
          <div className="mb-1 truncate text-sm font-medium text-[--xps-color]">
            {displayedTitle}
          </div>
        )}
        <div className="truncate text-sm">{displayedUrl}</div>
      </div>
      {children && <div className="ml-4 flex-shrink-0">{children}</div>}
    </a>
  );
};

export default LinkPreviewCard;
