import {z} from 'zod';

export const INSTAGRAM_URL = 'https://instagram.com';

export const InstagramMediaTypeSchema = z.enum(['CAROUSEL_ALBUM', 'IMAGE', 'VIDEO']);
export type InstagramMediaType = z.infer<typeof InstagramMediaTypeSchema>;

export const InstagramMediaProductTypeSchema = z.enum(['FEED', 'REELS']);
export type InstagramMediaProductType = z.infer<typeof InstagramMediaProductTypeSchema>;

export const InstagramMediaSchema = z.object({
  id: z.string(),
  authorUsername: z.string(),
  caption: z.string().nullable(),
  createdAt: z.date(), // Using DatetimeSchema fails here because the type thinks it's a string
  isCommentEnabled: z.boolean().optional(),
  mediaType: InstagramMediaTypeSchema,
  mediaProductType: InstagramMediaProductTypeSchema,
  permalink: z.string().url(),
  thumbnailUrl: z.string().url(),
});
export type InstagramMedia = z.infer<typeof InstagramMediaSchema>;

export const InstagramCommentSchema = z.object({
  id: z.string(),
  createdAt: z.date(), // Using DatetimeSchema fails here because the type thinks it's a string
  mediaId: z.string(),
  text: z.string(),
  userId: z.string(),
  username: z.string(),
});
export type InstagramComment = z.infer<typeof InstagramCommentSchema>;

export const FacebookPageSchema = z.object({
  id: z.string(),
  instagramBusinessAccountId: z.string().nullable(),
  name: z.string(),
});
export type FacebookPage = z.infer<typeof FacebookPageSchema>;

const InstagramConversationSchema = z.object({
  id: z.string(),
  updatedAt: z.date(),
  participants: z.array(
    z.object({
      id: z.string(),
      username: z.string(),
    })
  ),
  messages: z.array(
    z.object({
      id: z.string(),
      from: z.object({
        id: z.string(),
        username: z.string(),
      }),
      createdAt: z.date(),
      message: z.string(),
    })
  ),
});
export type InstagramConversation = z.infer<typeof InstagramConversationSchema>;

// example of reel: https://www.instagram.com/reel/:reelId
export const INSTAGRAM_REEL_BASE_URL = `${INSTAGRAM_URL}/reel/`;

// example of post: https://www.instagram.com/p/:postId
export const INSTAGRAM_POST_BASE_URL = `${INSTAGRAM_URL}/p/`;
