import {isCohortError} from '@cohort/shared/schema/common/errors';
import {getZodFlatSchemaFields} from '@cohort/shared/utils/zod';
import storageAvailable from '@cohort/shared-frontend/utils/storageAvailable';
import notify from '@cohort/wallet/components/toasts/Toast';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {loginUser} from '@cohort/wallet/lib/Authentication';
import {loginWithCustomToken, logoutUser} from '@cohort/wallet/lib/Authentication';
import {verifyAuthToken} from '@cohort/wallet/lib/Endpoints';
import {setCurrentUser, trackError} from '@cohort/wallet/lib/Tracking';
import sendMessageToParent from '@cohort/wallet/lib/WindowMessaging';
import {InitParamsWSchema} from '@cohort/wallet-schemas/init';
import {browserLocalPersistence, getAuth, inMemoryPersistence} from 'firebase/auth';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {z} from 'zod';
import {shallow} from 'zustand/shallow';

const LoginMessage = z.object({
  event: z.literal('login.authToken'),
  payload: z.object({
    authToken: z.string(),
  }),
});

export default function useAuthentication(): void {
  const initialized = useRef(false);
  const initFromSearchParams = useAppStore(store => store.initFromSearchParams);
  const {user, initEmail, login, logout, setIsAuthenticating} = useUserStore(
    store => ({
      user: store.user,
      initEmail: store.initEmail,
      login: store.login,
      logout: store.logout,
      setIsAuthenticating: store.setIsAuthenticating,
    }),
    shallow
  );
  const [enableAuthStateChange, setEnableAuthStateChange] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const enableDeferredAuth = useRef(false);
  const embedEmail = useRef<string>();
  const {t} = useTranslation('hooks', {keyPrefix: 'authentication'});

  const initCustomTokenAuthentication = useCallback(
    async (authToken: string) => {
      // We start by logging out the user - isAuthenticating will be set to false afterwards
      await logoutUser();
      setIsAuthenticating(true);
      const firebaseToken = await verifyAuthToken(authToken).catch(() => {
        setIsAuthenticating(false);
        setEnableAuthStateChange(true);
      });

      if (!firebaseToken) {
        return;
      }
      await loginWithCustomToken(firebaseToken);
      setEnableAuthStateChange(true);
    },
    [setIsAuthenticating]
  );

  // Application default setup
  // Parsing searchParams and initializing the authentication
  useEffect(() => {
    if (initialized.current) {
      return;
    }
    const config = initFromSearchParams(searchParams);

    if (config?.authToken) {
      initCustomTokenAuthentication(config.authToken);
    } else {
      // We expect an authToken when the app is embedded
      // If the app is embedded, we wait for the authToken to be sent by the parent window
      if (config?.embedded && config.customLoginUrl) {
        enableDeferredAuth.current = true;
        embedEmail.current = config.embedEmail;
        setEnableAuthStateChange(true);
      } else {
        setEnableAuthStateChange(true);
      }
    }
    initialized.current = true;
    const paramsWhitelist = getZodFlatSchemaFields(InitParamsWSchema);
    const paramsToDelete: Array<string> = [];

    searchParams.forEach((value, key) => {
      if (key in paramsWhitelist) {
        paramsToDelete.push(key);
      }
    });

    paramsToDelete.forEach(key => searchParams.delete(key));
    setSearchParams(searchParams, {replace: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams]);

  // Set auth persistence
  useEffect(() => {
    async function setAuthPersistence(): Promise<void> {
      const auth = getAuth();

      if (storageAvailable()) {
        await auth.setPersistence(browserLocalPersistence);
      } else {
        await auth.setPersistence(inMemoryPersistence);
      }
    }

    setAuthPersistence();
  }, []);

  // Initialize Firebase auth listener
  useEffect(() => {
    if (!enableAuthStateChange) {
      return;
    }
    const auth = getAuth();
    let logoutTimerId: NodeJS.Timeout;
    let intervalId: NodeJS.Timeout;

    const notifyAuthUpdate = (isLoggedIn: boolean): void => {
      sendMessageToParent({
        event: 'auth.updated',
        payload: {
          isLoggedIn,
        },
      });
    };

    const onMessage = (event: MessageEvent): void => {
      const message = LoginMessage.safeParse(event.data);

      if (message.success === false) {
        return;
      }
      initCustomTokenAuthentication(message.data.payload.authToken);
      clearTimeout(logoutTimerId);
      clearInterval(intervalId);
      window.removeEventListener('message', onMessage);
    };

    auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        try {
          // Since we try to preserve the user session in embed mode, we need to check if the embed email is the same
          // as the current user email to prevent race conditions
          if (
            enableDeferredAuth.current &&
            (embedEmail.current === undefined || firebaseUser.email !== embedEmail.current)
          ) {
            logoutUser();
            return;
          }
          const user = await loginUser(firebaseUser);
          const {claims} = await firebaseUser.getIdTokenResult();

          login(
            user,
            // Default to true for retrocompatibility
            claims.authenticated !== undefined ? Boolean(claims.authenticated) : true
          );
          setCurrentUser(user);
          notifyAuthUpdate(true);

          enableDeferredAuth.current = false;
        } catch (e) {
          if (isCohortError(e, 'user.not-found')) {
            logoutUser();
            notify('error', t('userNotFoundError'));
            return;
          }
          trackError(e);
        }
      } else if (enableDeferredAuth.current) {
        if (embedEmail.current === undefined) {
          logout();
          notifyAuthUpdate(false);
          setCurrentUser(undefined);
          return;
        }
        // We send the message multiple times to make sure the parent window receives it
        intervalId = setInterval(() => notifyAuthUpdate(false), 300);
        // We wait and expect a message from the parent window with the authToken
        window.addEventListener('message', onMessage);
        // We wait 10s before timeout and logging out the user
        logoutTimerId = setTimeout(() => {
          clearInterval(intervalId);
          window.removeEventListener('message', onMessage);
          logout();
          setCurrentUser(undefined);
        }, 10000);
        enableDeferredAuth.current = false;
      } else {
        logout();
        notifyAuthUpdate(false);
        setCurrentUser(undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, logout, enableAuthStateChange]);

  // Logout user on different email / brand
  useEffect(() => {
    if (user && initEmail && user.email !== initEmail) {
      logoutUser();
    }
  }, [user, initEmail]);
}
