import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@cohort/shared-frontend/components/Accordion';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import Navbar from '@cohort/wallet/components/navigation/NavBar';
import useSetTrackingConfig from '@cohort/wallet/hooks/setTrackingConfig';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import BaseLayout from '@cohort/wallet/layouts/BaseLayout';
import SpaceBurgerNavigation from '@cohort/wallet/layouts/space/SpaceBurgerNavigation';
import SpaceNavbarNavigation from '@cohort/wallet/layouts/space/SpaceNavbarNavigation';
import SpaceTabbar from '@cohort/wallet/layouts/space/SpaceTabbar';
import {List} from '@phosphor-icons/react';
import {AccordionTrigger} from '@radix-ui/react-accordion';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';
import {shallow} from 'zustand/shallow';

const SpaceLayout = (): JSX.Element => {
  const {t} = useTranslation('layouts', {
    keyPrefix: 'space.spaceLayout',
  });
  const {accentColor} = useThemeContext();
  const navigateWithTransition = useNavigateWithTransition();
  const {isMobile} = useScreenSize();
  const {navigationType, withNavbar} = useAppStore(
    store => ({
      navigationType: store.navigationType,
      withNavbar: store.withNavbar,
    }),
    shallow
  );
  const user = useUserStore(store => store.user);
  const merchant = useMerchantContext();
  const trackingReady = useSetTrackingConfig({merchant, rootPath: 'space'});

  const navbarEnabled = withNavbar && user !== null;

  const navbar = match({
    navbarEnabled,
    navigationType,
  })
    .with(
      {
        navbarEnabled: true,
      },
      () => (
        <Navbar
          handleBackButtonClick={() => {
            navigateWithTransition(-1, undefined, 'backward');
          }}
          logoFileKey={merchant.logoFileKey}
          appTitle={t('experienceSpaceTitle', {name: merchant.name})}
        >
          {isMobile && navigationType === 'burger' && <SpaceBurgerNavigation />}
          {!isMobile && navigationType !== 'none' && <SpaceNavbarNavigation />}
        </Navbar>
      )
    )
    .with(
      {
        navbarEnabled: false,
        navigationType: 'burger',
      },
      () => (
        <Accordion type="single" collapsible className="z-40 w-full px-4 py-2">
          <AccordionItem value="navbar" className="border-0">
            <AccordionTrigger className="block p-2 pr-0 md:pb-0">
              <List color={accentColor} size={32} />
            </AccordionTrigger>
            <AccordionContent className="ml-1 p-2 md:pb-0">
              <SpaceBurgerNavigation />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )
    )
    .otherwise(() => <div />);

  if (!trackingReady) {
    return <LoadingScreen />;
  }

  return (
    <BaseLayout
      navbar={navbar}
      footer={isMobile && user && navigationType === 'tabbar' ? <SpaceTabbar /> : <Fragment />}
    />
  );
};

export default SpaceLayout;
