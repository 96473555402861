import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortContentViewedPropertiesSchema = z.object({
  contentId: z.string().uuid(),
  contentInternalName: z.string(),
  contentMediaKind: z.string(),
});
export type CohortContentViewedProperties = z.infer<typeof CohortContentViewedPropertiesSchema>;

export type CohortContentViewedEventStruct = UserEventStruct<
  'cohort.content.viewed',
  keyof CohortContentViewedProperties,
  CohortContentViewedProperties,
  CohortContentViewedProperties
>;

export const CohortContentViewedEventSpec: UserEventSpec<CohortContentViewedEventStruct> = {
  id: 'cohort.content.viewed',
  name: 'Viewed a Content',
  description: 'Event Triggerred when a user views a Content',
  propertiesSchema: CohortContentViewedPropertiesSchema,
  exposedPropertiesSchema: CohortContentViewedPropertiesSchema,
  resources: {
    contentId: 'cohort.content',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['contentId'],
  },
};
