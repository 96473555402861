import type {CohortFormAnswer, CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import CohortFormStepper from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormStepper';
import {ModalOrDrawer} from '@cohort/wallet/components/modals/Modal';
import {useListUserAttributes} from '@cohort/wallet/hooks/api/UserAttributes';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import React from 'react';
import {compact, uniq} from 'remeda';

type CohortFormComponentProps = {
  config: CohortFormConfig;
  onSubmitCallback: (values: Record<string, CohortFormAnswer>) => void;
  isLoading: boolean;
  onClose: () => void;
  trackingMetadata: TrackingConfig['metadata'];
};

const CohortFormModal: React.FC<CohortFormComponentProps> = ({
  config,
  onClose,
  onSubmitCallback,
  isLoading,
  trackingMetadata,
}) => {
  const userPropertyIds = uniq(compact(config.prompts.map(prompt => prompt.userPropertyId)));
  const {data: userAttributes} = useListUserAttributes(userPropertyIds);

  if (userAttributes === undefined) {
    return null;
  }

  const tracking = {
    namespace: 'modals.cohortForm',
    metadata: trackingMetadata,
  } satisfies TrackingConfig;

  return (
    <ModalOrDrawer
      tracking={tracking}
      onClose={onClose}
      modalProps={{
        className: 'flex min-h-[700px] flex-col',
        width: 600,
      }}
      drawerProps={{
        height: 'full',
      }}
    >
      <CohortFormStepper
        config={config}
        onClose={onClose}
        isLoading={isLoading}
        userAttributes={userAttributes}
        onSubmitTracking={tracking}
        onSubmitCallback={onSubmitCallback}
      />
    </ModalOrDrawer>
  );
};

export default CohortFormModal;
