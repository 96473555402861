import {SSO_GOOGLE_DEFAULT_PROVIDER} from '@cohort/shared/constants';
import {logIn} from '@cohort/wallet/lib/Endpoints';
import {getAmplitudeSessionId, getTraceId} from '@cohort/wallet/lib/Tracking';
import type {MerchantWDto} from '@cohort/wallet-schemas/merchant';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import {FirebaseError, initializeApp} from 'firebase/app';
import type {User} from 'firebase/auth';
import {
  getAuth,
  OAuthProvider,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import {getI18n} from 'react-i18next';

export function initFirebase(): void {
  initializeApp({
    apiKey: import.meta.env.COHORT_FIREBASE_API_KEY,
    authDomain: import.meta.env.COHORT_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.COHORT_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.COHORT_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.COHORT_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.COHORT_FIREBASE_APP_ID,
    measurementId: import.meta.env.COHORT_FIREBASE_MEASUREMENT_ID,
  });
}

export async function loginUser(firebaseUser: User): Promise<UserWDto> {
  const bearer = await firebaseUser.getIdToken();
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'Accept-Language': getI18n().language,
    Authorization: `Bearer ${bearer}`,
    'Cohort-Trace-Id': getTraceId(),
    'Amplitude-Session-Id': getAmplitudeSessionId()?.toString() ?? '',
  };

  const response = await logIn(headers);
  return response;
}

export async function logoutUser(): Promise<void> {
  const auth = getAuth();

  await auth.signOut();
}

export async function loginWithGoogle(merchant: MerchantWDto, emailHint?: string): Promise<void> {
  const auth = getAuth();

  auth.tenantId = merchant.authTenantId;
  const provider = new OAuthProvider(SSO_GOOGLE_DEFAULT_PROVIDER.providerId);
  if (emailHint !== undefined) {
    provider.setCustomParameters({login_hint: emailHint});
  }
  try {
    await signInWithPopup(auth, provider);
  } catch (err: unknown) {
    if (err instanceof FirebaseError && err.code === 'auth/popup-blocked') {
      await signInWithRedirect(auth, provider);
    }
    throw err;
  }
}

export async function loginWithCustomToken(token: string): Promise<void> {
  const auth = getAuth();
  const {tenant_id: tenantId} = jwt_decode<{tenant_id: string}>(token);

  auth.tenantId = tenantId;
  await signInWithCustomToken(auth, token);
}
