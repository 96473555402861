import TwitterEmbedPost from '@cohort/components-xps/components/apps/twitter/TwitterEmbedPost';
import i18n from '@cohort/components-xps/lib/i18n';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';

const TwitterPostVisualPreview: React.FC<VisualPreviewProps<TwitterPostMediaStruct>> = ({
  config,
}) => {
  const {hasDarkBg} = useThemeContext();
  const currentLanguage = formatI18nLanguage(i18n.language);

  return (
    <div className="pointer-events-none relative aspect-video w-full overflow-hidden">
      <TwitterEmbedPost
        tweetId={config.postId}
        theme={hasDarkBg ? 'dark' : 'light'}
        lang={currentLanguage}
      />
    </div>
  );
};

export default TwitterPostVisualPreview;
