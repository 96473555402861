import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';
import {isString} from 'remeda';

const MediaContentTikTokPost: React.FC<MediaContentProps<TikTokPostMediaStruct>> = ({
  config,
  description,
  title,
}) => (
  <div className="space-y-4">
    {isString(config?.videoId) && (
      <TiktokEmbedPost videoId={config.videoId} width="100%" aspect="aspect-video" />
    )}
    <MediaContentInfos title={title} description={description} />
  </div>
);

export default MediaContentTikTokPost;
