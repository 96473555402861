import RichText from '@cohort/shared-frontend/components/RichText';

type MediaContentInfosProps = {
  title: string;
  description?: string | null;
};

/**
 * Title and description of the media content.
 */
const MediaContentInfos: React.FC<MediaContentInfosProps> = ({title, description}) => (
  <div className="flex flex-col gap-y-2">
    <h2 className="text-lg font-semibold">{title}</h2>
    {description && <RichText className="text-sm" html={description} />}
  </div>
);

export default MediaContentInfos;
