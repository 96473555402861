import {z} from 'zod';

export const CustomComponentTypeSchema = z.enum([
  'digital-asset',
  'challenge',
  'membership-pass',
  'perk',
  'reward-box',
]);
export type CustomComponentType = z.infer<typeof CustomComponentTypeSchema>;
