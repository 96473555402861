import {DatetimeSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const RewardDistributionSchema = z.union([
  z.object({
    distributionMode: z.literal('direct'),
    unlockMode: z.null(),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('none'),
    unlockMode: z.null(),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('random'),
    unlockMode: z.literal('instant'),
    unlockAt: z.null(),
  }),
  z.object({
    distributionMode: z.literal('random'),
    unlockMode: z.literal('deferred'),
    unlockAt: DatetimeSchema,
  }),
]);
export type RewardDistribution = z.infer<typeof RewardDistributionSchema>;

export const isMysteryBoxReward = (reward: {
  distributionMode: RewardDistribution['distributionMode'];
}): boolean => reward.distributionMode === 'random';

export const computeRewardOdds = (
  rewardSupply: number | null,
  totalSupply: number | null
): number | undefined => {
  if (rewardSupply === null || totalSupply === null) {
    return undefined;
  }
  return rewardSupply / totalSupply;
};

export const computeNoRewardOdds = (rewardConfiguration: {
  totalSupply: number | null;
  perks: {totalSupply: number | null}[];
  collections: {totalSupply: number | null}[];
}): number => {
  if (rewardConfiguration.totalSupply === null) {
    return 0;
  }
  const totalRewardSupply = [
    ...rewardConfiguration.perks,
    ...rewardConfiguration.collections,
  ].reduce((acc, reward) => acc + (reward.totalSupply ?? 0), 0);
  return (rewardConfiguration.totalSupply - totalRewardSupply) / rewardConfiguration.totalSupply;
};
