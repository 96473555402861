import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {ConnectButtonProps} from '@cohort/wallet/apps/ConnectButton';
import DataPolicyModal from '@cohort/wallet/apps/DataPolicyModal';
import Button from '@cohort/wallet/components/button/Button';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {getOauthAuthorizationUrl} from '@cohort/wallet/lib/Endpoints';
import {Info} from '@phosphor-icons/react';
import {useState} from 'react';
import {shallow} from 'zustand/shallow';

const OAuthConnectButton: React.FC<ConnectButtonProps> = ({
  app,
  connectorId,
  existingConnectionId,
  userDataPolicyPosition = 'left',
  ...props
}) => {
  const [dataPolicyModal, setDataPolicyModal] = useState(false);
  const {embedUrl, embedded} = useAppStore(
    store => ({
      embedUrl: store.embedUrl,
      embedded: store.embedded,
    }),
    shallow
  );
  const {mutate: startConnectionFlow, isLoading} = useCohortMutation({
    mutationFn: async () =>
      getOauthAuthorizationUrl({
        connectorId,
        embedUrl: embedUrl ?? null,
        xpsRedirectUrl: window.location.href,
        existingConnectionId,
      }),
    onSuccess: ({authorizationUrl}) =>
      embedded
        ? window.parent.location.replace(authorizationUrl)
        : window.location.replace(authorizationUrl),
  });

  return (
    <div>
      {dataPolicyModal && <DataPolicyModal app={app} onClose={() => setDataPolicyModal(false)} />}
      <div className="flex items-center gap-2">
        {app.withUserDataPolicy && (
          <Button
            variant="secondary"
            className={cn(
              props.className,
              '!w-auto !rounded-xl !px-2 !py-2',
              userDataPolicyPosition === 'left' ? 'order-first' : 'order-last'
            )}
            onClick={() => setDataPolicyModal(true)}
            tracking={{
              namespace: 'apps.userDataPolicy',
              metadata: {appId: app.spec.id},
            }}
          >
            {props.icon}
            <Info size={20} />
          </Button>
        )}
        <Button
          variant="primary"
          {...props}
          className={cn(
            props.className,
            userDataPolicyPosition === 'left' ? 'order-last' : 'order-first'
          )}
          loading={isLoading}
          onClick={() => startConnectionFlow()}
        >
          {props.icon}
          {props.text}
        </Button>
      </div>
    </div>
  );
};

export default OAuthConnectButton;
