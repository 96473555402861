import {MediaKindSchema} from '@cohort/shared/apps';
import {DatetimeSchema} from '@cohort/shared/schema/common';
import {ContentAudienceSchema} from '@cohort/shared/schema/common/content';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {z} from 'zod';

export const MAX_PREVIEW_CONTENTS_PER_CATEGORY = 5;

export const ContentWSchema = z.object({
  id: z.string().uuid(),
  cohortId: z.string().uuid().nullable(),
  title: z.string(),
  description: z.string().nullable(),
  media: z.object({
    kind: MediaKindSchema,
    externalId: z.string().nullable(),
    config: z.any(),
  }),
  isHighlighted: z.boolean(),
  audience: ContentAudienceSchema,
  publishedAt: DatetimeSchema,
});
export type ContentWDto = z.infer<typeof ContentWSchema>;

export const ContentCategoryWSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  slug: z.string(),
  rank: z.number(),
});
export type ContentCategoryWDto = z.infer<typeof ContentCategoryWSchema>;

export const ContentsPreviewByCategoryWSchema = z.object({
  category: ContentCategoryWSchema.nullable(), // null for uncategorized contents
  contents: z.array(ContentWSchema).max(MAX_PREVIEW_CONTENTS_PER_CATEGORY),
});
export type ContentsPreviewByCategoryWDto = z.infer<typeof ContentsPreviewByCategoryWSchema>;

export const ContentsPreviewByCategoriesWSchema = z.array(ContentsPreviewByCategoryWSchema);
export type ContentsPreviewByCategoriesWDto = z.infer<typeof ContentsPreviewByCategoriesWSchema>;

export const GetPaginatedContentsParamsWSchema = PaginationParamsSchema.extend({
  categorySlug: z.string().optional(),
});
export type GetPaginatedContentsParamsWDto = z.infer<typeof GetPaginatedContentsParamsWSchema>;
