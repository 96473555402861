import {z} from 'zod';

export const AssetWSchema = z.object({
  fileKey: z.string(),
  mimeType: z.string(),
});
export type AssetWDto = z.infer<typeof AssetWSchema>;

export const SignedUrlWSchema = z.object({
  signedUrl: z.string(),
  fileKey: z.string(),
  maxFileSize: z.number(),
});
export type SignedUrlWDto = z.infer<typeof SignedUrlWSchema>;
