import {DatetimeSchema} from '@cohort/shared/schema/common';
import {RewardDistributionSchema} from '@cohort/shared/schema/common/rewards';
import {DigitalAssetCollectionWSchema} from '@cohort/wallet-schemas/digitalAssetCollection';
import {OwnershipWSchema} from '@cohort/wallet-schemas/ownership';
import {PerkWSchema} from '@cohort/wallet-schemas/perk';
import {PerkSpaceViewWSchema} from '@cohort/wallet-schemas/perkAccess';
import {z} from 'zod';

const RewardSupplySchema = z.object({
  totalSupply: z.number().int().nullable(),
});

export const RewardConfigurationWSchema = RewardDistributionSchema.and(
  z.object({
    title: z.string().nullable(),
    description: z.string().nullable(),
    imageFileKey: z.string().nullable(),
    totalSupply: z.number().int().nullable(),
    availableSupply: z.number().int().nullable(),
    perks: z.array(PerkWSchema.and(RewardSupplySchema)),
    collections: z.array(DigitalAssetCollectionWSchema.and(RewardSupplySchema)),
    unlockedAt: DatetimeSchema.nullable(),
  })
);
export type RewardConfigurationWDto = z.infer<typeof RewardConfigurationWSchema>;

export const RewardBoxWSchema = RewardConfigurationWSchema.and(
  z.object({
    id: z.string(),
    locked: z.boolean(),
    openedAt: DatetimeSchema.nullable(),
  })
);
export type RewardBoxWDto = z.infer<typeof RewardBoxWSchema>;

export const RewardBoxRewardsWSchema = z.object({
  perks: z.array(PerkSpaceViewWSchema),
  ownerships: z.array(OwnershipWSchema),
});
export type RewardBoxRewardsWDto = z.infer<typeof RewardBoxRewardsWSchema>;
