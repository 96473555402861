import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import type {
  CohortFormAnswer,
  CohortFormConfig,
  CohortFormPrompt,
  SelectCohortFormPrompt,
} from '@cohort/shared/schema/common/cohortForm';
import {
  CohortFormAnswerSchema,
  SelectCohortFormPromptSchema,
} from '@cohort/shared/schema/common/cohortForm';
import {buildLocalizationConfig, formatI18nLanguage} from '@cohort/shared/utils/localization';
import CohortFormPromptInput from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormPromptInput';
import {validateCohortFormPrompt} from '@cohort/wallet/apps/cohort-form/utils';
import Button from '@cohort/wallet/components/button/Button';
import {
  Carousel,
  CarouselItem,
  CarouselProgress,
  useCarousel,
} from '@cohort/wallet/components/carousel/Carousel';
import {CarouselContent} from '@cohort/wallet/components/carousel/Carousel';
import {ModalHeader} from '@cohort/wallet/components/modals/Modal';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import {uploadAsset} from '@cohort/wallet/lib/Utils';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import {ArrowCircleLeft} from '@phosphor-icons/react';
import i18n from 'i18next';
import React, {useState} from 'react';
import {FormProvider, useForm, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type CohortFormValues = Record<string, CohortFormAnswer>;

type CohortFormControlsProps = {
  isLoading: boolean;
  onClose: () => void;
  children: React.ReactNode;
  prompts: Array<CohortFormPrompt>;
  onSubmitTracking: TrackingConfig;
  onSubmitCallback: (values: CohortFormValues) => void;
  setFormErrors: (errors: Record<string, string>) => void;
};

const CohortFormControls: React.FC<CohortFormControlsProps> = ({
  prompts,
  onClose,
  children,
  isLoading,
  setFormErrors,
  onSubmitTracking,
  onSubmitCallback,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'modalStepper.cohortFormStepper',
  });
  const notify = useNotify();
  const {accentColor} = useThemeContext();
  const {selectedIndex, scrollNext, scrollPrev, canScrollPrev, canScrollNext} = useCarousel();
  const currentPrompt = prompts[selectedIndex];

  const {getValues, handleSubmit} = useFormContext();

  const {isLoading: isUploading, mutateAsync: uploadFile} = useCohortMutation({
    mutationFn: async ({file, assetKind}: {file: File; assetKind: WalletAssetKind}) =>
      uploadAsset(file, assetKind),
    onError: err => notify('error', t('errorFileUploadFailed')),
  });

  const validateForm = async (data: CohortFormValues): Promise<void> => {
    const values: CohortFormValues = {};
    const submitErrors: Record<string, string> = {};

    for (const prompt of prompts) {
      const {value, error} = await validateCohortFormPrompt(prompt, data, uploadFile);
      values[prompt.id] = value;
      if (error) {
        submitErrors[prompt.id] = error;
      }
    }

    if (Object.keys(submitErrors).length > 0) {
      setFormErrors(submitErrors);
      return;
    }
    onSubmitCallback(values);
  };

  if (!currentPrompt) {
    throw new Error(`Never happens: no prompt found at index ${selectedIndex}.`);
  }

  // i18nOwl-ignore [cohortFormControls.checkboxRequired, cohortFormControls.invalidEmail, cohortFormControls.required]
  const handleNextClick = async (): Promise<void> => {
    const {error} = await validateCohortFormPrompt(currentPrompt, getValues());
    if (error) {
      setFormErrors({[currentPrompt.id]: t(`cohortFormControls.${error}`)});
      return;
    }
    scrollNext();
  };

  const onPromptChangeTracking = (action: 'prevStep' | 'nextStep'): TrackingConfig => ({
    namespace: onSubmitTracking.namespace,
    metadata: {
      integrationId: 'cohort-form.fill-form',
      action: `formControls.${action}`,
    },
  });

  return (
    <>
      <ModalHeader onClose={onClose}>
        <div className="grid h-8 grid-cols-5">
          <div className="col-span-1 h-8">
            {canScrollPrev && (
              <Button
                variant="link"
                className="!p-0"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault();
                  scrollPrev();
                }}
                tracking={canScrollNext ? onPromptChangeTracking('prevStep') : onSubmitTracking}
              >
                <ArrowCircleLeft size={32} weight="fill" />
              </Button>
            )}
          </div>
          <div className="col-span-4">
            {prompts.length > 1 && (
              <CarouselProgress
                disableClick
                color={accentColor}
                variant="progressBar"
                label={t('cohortFormControls.progressBarLabel', {
                  currentIndex: selectedIndex + 1,
                  totalIndex: prompts.length,
                })}
              />
            )}
          </div>
        </div>
      </ModalHeader>

      {children}

      <Button
        variant="primary"
        size="default"
        className="mt-4 w-full"
        type={canScrollNext ? 'button' : 'submit'}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          if (canScrollNext) {
            handleNextClick();
          } else {
            handleSubmit(validateForm)();
          }
        }}
        loading={isLoading || isUploading}
        tracking={canScrollNext ? onPromptChangeTracking('nextStep') : onSubmitTracking}
      >
        {canScrollNext ? t('cohortFormControls.next') : t('cohortFormControls.submit')}
      </Button>
    </>
  );
};

type CohortFormPromptStepperProps = {
  isLoading: boolean;
  onClose: () => void;
  config: CohortFormConfig;
  onSubmitTracking: TrackingConfig;
  userAttributes: Array<UserAttributeWDto>;
  onSubmitCallback: (values: CohortFormValues) => void;
};

const CohortFormStepper: React.FC<CohortFormPromptStepperProps> = ({
  config,
  onClose,
  isLoading,
  userAttributes,
  onSubmitTracking,
  onSubmitCallback,
}) => {
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const merchant = useMerchantContext();
  const {prompts} = config;

  // include user attributes in default values
  const defaultValues: CohortFormValues = {};
  for (const prompt of prompts) {
    const userAttributeValue = userAttributes.find(
      attribute => attribute.userPropertyId === prompt.userPropertyId
    )?.value;

    const answerFromUserAttribute = userAttributeValue
      ? CohortFormAnswerSchema.parse(userAttributeValue)
      : null;
    defaultValues[prompt.id] = answerFromUserAttribute ?? '';

    if (prompt.type === 'checkbox') {
      defaultValues[prompt.id] = false;
    }

    if (
      SelectCohortFormPromptSchema.safeParse(prompt).success &&
      (prompt as SelectCohortFormPrompt).multipleChoice
    ) {
      defaultValues[prompt.id] = [];
    }
  }

  const localizationConfig = buildLocalizationConfig(
    formatI18nLanguage(i18n.language),
    merchant.supportedLanguages,
    merchant.defaultLanguage
  );

  return (
    <div className="flex h-full w-full grow flex-col gap-8">
      <FormProvider
        {...useForm({
          defaultValues,
        })}
      >
        <form className="flex grow flex-col">
          <Carousel isDraggable={false} className="flex grow flex-col">
            <CohortFormControls
              prompts={prompts}
              onClose={onClose}
              setFormErrors={setFormErrors}
              onSubmitTracking={onSubmitTracking}
              onSubmitCallback={onSubmitCallback}
              isLoading={isLoading}
            >
              <CarouselContent className="grow items-center">
                {config.prompts.map(prompt => (
                  <CarouselItem key={prompt.id}>
                    <CohortFormPromptInput
                      prompt={prompt}
                      localizationConfig={localizationConfig}
                      formErrors={formErrors}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </CohortFormControls>
          </Carousel>
        </form>
      </FormProvider>
    </div>
  );
};

export default CohortFormStepper;
