import Title from '@cohort/components-xps/components/Title';
import type {App} from '@cohort/wallet/apps';
import Button from '@cohort/wallet/components/button/Button';
import {ModalOrDrawer} from '@cohort/wallet/components/modals/Modal';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {Trans, useTranslation} from 'react-i18next';

type DataPolicyModalProps = {
  app: App;
  onClose: () => void;
};

const DataPolicyModal: React.FC<DataPolicyModalProps> = ({app, onClose}) => {
  const {accentColor} = useThemeContext();
  const {t} = useTranslation('apps', {keyPrefix: 'dataPolicyModal'});

  const ModalContent = (
    <div className="md:max-w-[50vw]">
      <p className="text-sm">
        <Trans
          i18nKey="userDataPolicy"
          ns={`app-${app.spec.id}`}
          components={{
            externalLink: (
              <a
                style={{color: accentColor}}
                className="cursor-pointer"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      </p>
      <Button
        variant="secondary"
        className="mt-4 w-full"
        onClick={onClose}
        tracking={{namespace: 'apps.userDataPolicy.close'}}
      >
        {t('closeModal')}
      </Button>
    </div>
  );

  const title = <Title>{t('userDataPolicyModalTitle')}</Title>;

  return (
    <ModalOrDrawer
      modalProps={{title}}
      drawerProps={{title}}
      tracking={{namespace: 'modals.apps.userDataPolicy'}}
      onClose={onClose}
    >
      {ModalContent}
    </ModalOrDrawer>
  );
};

export default DataPolicyModal;
