import {ConnectorIdSchema} from '@cohort/shared/apps';
import {ConnectionStatusSchema} from '@cohort/shared/schema/common/connection';
import {UserPropertyValueSchema} from '@cohort/shared/schema/common/userProperty';
import {z} from 'zod';

export const ConnectionWSchema = z.object({
  id: z.string(),
  connectorId: ConnectorIdSchema,
  status: ConnectionStatusSchema,
  profile: z
    .object({
      id: z.string(),
      displayName: z.string(),
      properties: z.record(UserPropertyValueSchema.nullable()),
    })
    .nullable(),
});
export type ConnectionWDto = z.infer<typeof ConnectionWSchema>;

// Get Connections

export const GetConnectionsResponseWSchema = z.object({
  connections: z.array(ConnectionWSchema),
});
export type GetConnectionsResponseWDto = z.infer<typeof GetConnectionsResponseWSchema>;

// Get Connection

export const GetConnectionResponseWSchema = z.object({
  connection: ConnectionWSchema.nullable(),
});
export type GetConnectionResponseWDto = z.infer<typeof GetConnectionResponseWSchema>;

// Get Authorization URL

export const GetAuthorizationUrlDataWSchema = z.object({
  connectorId: ConnectorIdSchema,
  embedUrl: z.string().nullable(),
  xpsRedirectUrl: z.string().url(),
  context: z.record(z.unknown()).optional(),
  existingConnectionId: z.string().optional(),
});
export type GetAuthorizationUrlDataWDto = z.infer<typeof GetAuthorizationUrlDataWSchema>;

export const GetAuthorizationUrlResponseWSchema = z.object({
  authorizationUrl: z.string(),
});
export type GetAuthorizationUrlResponseWDto = z.infer<typeof GetAuthorizationUrlResponseWSchema>;

// Create Connection

export const CreateCustomConnectionDataWSchema = z.object({
  connectorId: ConnectorIdSchema,
  credentials: z.unknown(),
  /**
   * Pass this if you want to recreate an existing connection.
   */
  existingConnectionId: z.string().uuid().optional(),
});
export type CreateCustomConnectionDataWDto = z.infer<typeof CreateCustomConnectionDataWSchema>;
