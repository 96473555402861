import type {ConnectorId} from '@cohort/shared/apps';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import type {App, ConnectionErrorMessage} from '@cohort/wallet/apps';
import type {OAuthError, OAuthErrorCode} from '@cohort/wallet/lib/oAuth';
import {OAuthErrorList, oauthErrorTranslationMapping} from '@cohort/wallet/lib/oAuth';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

type UseOauthErrorOutput = {
  oauthError: OAuthError | undefined;
  setOauthError: (error: OAuthError | undefined) => void;
  getErrorMessage: (
    app: App | undefined,
    oauthError: OAuthError | undefined
  ) => ConnectionErrorMessage | undefined;
};

export default function useOauthError(): UseOauthErrorOutput {
  const {t} = useTranslation('components');

  const [searchParams, setSearchParams] = useSearchParams();
  const [oauthError, setOauthError] = useState<OAuthError | undefined>(() => {
    if (searchParams.has('oauthError') && searchParams.has('oauthConnectorId')) {
      const errorCode = searchParams.get('oauthError') as OAuthErrorCode;
      const cause = searchParams.get('oauthErrorCause') as CohortErrorCode | undefined;
      const connectorId = searchParams.get('oauthConnectorId') as ConnectorId;

      return OAuthErrorList.includes(errorCode)
        ? ({
            connectorId: connectorId,
            cause: cause,
            errorCode,
          } satisfies OAuthError)
        : undefined;
    }
    return undefined;
  });

  useEffect(() => {
    if (searchParams.has('oauthError') && searchParams.has('oauthConnectorId')) {
      searchParams.delete('oauthError');
      searchParams.delete('oauthConnectorId');
      searchParams.delete('oauthErrorCause');
      setSearchParams(searchParams, {replace: true});
    }
  }, [searchParams, setSearchParams]);

  const getErrorMessage = useCallback(
    (
      app: App | undefined,
      oauthError: OAuthError | undefined
    ): ConnectionErrorMessage | undefined => {
      let errorMessage: ConnectionErrorMessage | undefined = undefined;

      if (oauthError?.cause) {
        errorMessage = app?.customConnectionErrorMessage?.(oauthError.cause);
      }

      if (
        errorMessage === undefined &&
        oauthError &&
        oauthErrorTranslationMapping[oauthError.errorCode]
      ) {
        const translationKey = oauthErrorTranslationMapping[oauthError.errorCode];
        errorMessage = {
          title: t(`modals.oauth.oauthErrorModal.${translationKey}.title`),
          description: t(`modals.oauth.oauthErrorModal.${translationKey}.description`),
        };
      }

      return errorMessage;
    },
    [t]
  );

  return {oauthError, setOauthError, getErrorMessage};
}
