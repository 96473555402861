import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {
  contentVisualPreviewEmptyImageElement,
  contentVisualPreviewImageElement,
} from '@cohort/wallet/apps/utils/contents';
import {Video} from '@phosphor-icons/react';

const CohortVideoVisualPreview: React.FC<VisualPreviewProps<CohortVideoMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => {
  const thumbnailFileKey = config.thumbnailFileKey;
  const thumbnailSrc = thumbnailFileKey
    ? getImageUrl(import.meta.env.COHORT_ENV, thumbnailFileKey, {
        h: Sizes[imageSize],
        w: Sizes[imageSize],
      })
    : null;
  return thumbnailSrc
    ? contentVisualPreviewImageElement(title, thumbnailSrc)
    : contentVisualPreviewEmptyImageElement(<Video />);
};

export default CohortVideoVisualPreview;
