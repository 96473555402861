import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {SftpCredentialsSchema} from '@cohort/shared/sftp';
import {z} from 'zod';

export const BazaarvoiceCredentialsSchema = z.object({
  sftpServer: SftpCredentialsSchema,
});
export type BazaarvoiceCredentials = z.infer<typeof BazaarvoiceCredentialsSchema>;

export type BazaarvoiceConnectorStruct = ConnectorStruct<
  'bazaarvoice',
  null,
  BazaarvoiceCredentials,
  BaseProfileProperties,
  null
>;

export const BazaarvoiceConnectorSpec = {
  id: 'bazaarvoice',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: BazaarvoiceCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<BazaarvoiceConnectorStruct>;
