import MediaContentCohortVideo from '@cohort/components-xps/components/contents/apps/cohort/MediaContentCohortVideo';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortVideoMediaStruct} from '@cohort/shared/apps/cohort/medias/video';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';

const CohortVideoDisplayComponent: React.FC<MediaContentProps<CohortVideoMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  const formattedConfig = config
    ? {
        ...config,
        videoFileKey: getVideoUrl(import.meta.env.COHORT_ENV, config.videoFileKey),
        thumbnailFileKey: config.thumbnailFileKey
          ? getImageUrl(import.meta.env.COHORT_ENV, config.thumbnailFileKey, {
              h: Sizes.M,
              w: Sizes.M,
            })
          : null,
      }
    : config;

  return (
    <MediaContentCohortVideo config={formattedConfig} title={title} description={description} />
  );
};

export default CohortVideoDisplayComponent;
