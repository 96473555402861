/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {PerkIntegrationStruct} from '@cohort/shared/apps/app';
import type {QueryOptions} from '@cohort/wallet/hooks/api/Query';
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {
  activatePerkAccess,
  doUsePerkAccess,
  generatePrivateContentUrl,
  getPerkAccess,
} from '@cohort/wallet/lib/Endpoints';

export const perkAccessesKeys = {
  perkAccesses: ['perkAccesses'] as const,
  getByAccessId: (perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, perkAccessId, 'get-by-id'] as const,
  generatePrivateContentUrl: (perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, perkAccessId, 'generate-private-content-url'] as const,
  activatePerkAccess: (perkAccessId: string) =>
    [...perkAccessesKeys.perkAccesses, perkAccessId, 'activate-perk-access'] as const,
  consumePerkAccess: (perkAccessId: string, connectionId: string | null) =>
    [...perkAccessesKeys.perkAccesses, perkAccessId, connectionId, 'consume-perk-access'] as const,
};

export const usePerkAccess = (perkAccessId: string, options?: QueryOptions) => {
  return useCohortQuery({
    queryKey: perkAccessesKeys.getByAccessId(perkAccessId),
    queryFn: async () => getPerkAccess(perkAccessId),
    ...options,
    refetchInterval: options?.autoRefresh === true ? 5000 : false,
  });
};

export const useGeneratePrivateContentUrl = (perkAccessId: string, enabled?: boolean) => {
  return useCohortQuery({
    queryKey: perkAccessesKeys.generatePrivateContentUrl(perkAccessId),
    queryFn: async () => generatePrivateContentUrl(perkAccessId),
    enabled,
  });
};

export const useActivatePerkAccess = (perkAccessId: string, enabled?: boolean) => {
  return useCohortQuery({
    queryKey: perkAccessesKeys.activatePerkAccess(perkAccessId),
    queryFn: async () => activatePerkAccess(perkAccessId),
    enabled,
  });
};

export const useConsumePerkAccess = <T extends PerkIntegrationStruct = PerkIntegrationStruct>(
  perkAccessId: string,
  connectionId: string | null,
  usageInputData: T['UsageInputData'],
  enabled?: boolean
) => {
  return useCohortQuery({
    queryKey: perkAccessesKeys.consumePerkAccess(perkAccessId, connectionId),
    queryFn: async () => doUsePerkAccess(perkAccessId, connectionId, usageInputData),
    enabled,
  });
};
