import type {AppSpec, AppStruct, PerkIntegrationSpec} from '@cohort/shared/apps/app';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/fillForm';
import {CohortSwipeCardsTriggerIntegrationSpec} from '@cohort/shared/apps/cohort-form/triggers/swipeCards';
import type {TriggerIntegrationSpec} from '@cohort/shared/apps/trigger';

export const CohortPerkIntegrationSpecs = [
  CohortFormPerkIntegrationSpec,
] as const satisfies ReadonlyArray<PerkIntegrationSpec>;
type CohortPerkIntegrationIds = (typeof CohortPerkIntegrationSpecs)[number]['id'];

export const CohortTriggerIntegrationSpecs = [
  CohortFillFormTriggerIntegrationSpec,
  CohortSwipeCardsTriggerIntegrationSpec,
] as const satisfies ReadonlyArray<TriggerIntegrationSpec>;
type CohortTriggerIntegrationIds = (typeof CohortTriggerIntegrationSpecs)[number]['id'];

export type CohortFormAppStruct = AppStruct<
  'cohort-form',
  null,
  null,
  null,
  never,
  never,
  CohortPerkIntegrationIds,
  CohortTriggerIntegrationIds,
  never,
  never,
  never,
  never
>;

export const CohortFormAppSpec: AppSpec<CohortFormAppStruct> = {
  id: 'cohort-form',
  name: 'Cohort Form',
  merchantConnector: null,
  userConnector: null,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: CohortPerkIntegrationSpecs,
  triggerIntegrationSpecs: CohortTriggerIntegrationSpecs,
  userEventSpecs: [],
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
