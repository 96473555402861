import {cn} from '@cohort/shared-frontend/utils/classNames';
import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import {Star} from '@phosphor-icons/react';
import React, {useEffect} from 'react';
import type {Control, FieldValues, Path} from 'react-hook-form';
import {useController} from 'react-hook-form';

type ScoreInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  label?: string;
  mandatory?: boolean;
  width?: string;
  error?: JSX.Element;
  className?: string;
};

const SCORE_VALUES = [1, 2, 3, 4, 5];
export const ScoreInput: React.FC<ScoreInputProps<FieldValues>> = ({
  name,
  control,
  label,
  mandatory,
  width,
  error,
  className,
}) => {
  const {field} = useController({name, control});

  useEffect(() => {
    if (
      field.value !== null &&
      field.value !== undefined &&
      field.value !== '' &&
      !SCORE_VALUES.includes(field.value)
    ) {
      field.onChange(null);
    }
  }, [field]);

  return (
    <FieldWrapper className={width} label={label} mandatory={mandatory} error={error}>
      <div className={cn('flex gap-1', className)}>
        {SCORE_VALUES.map(value => (
          <button key={value} type="button" onClick={() => field.onChange(value)}>
            {value <= field.value ? (
              <Star className="h-6 w-6 text-yellow-400" weight="fill" />
            ) : (
              <Star className="h-6 w-6 text-[var(--xps-p-color)] opacity-30" weight="duotone" />
            )}
          </button>
        ))}
      </div>
    </FieldWrapper>
  );
};

ScoreInput.displayName = 'ScoreInput';
