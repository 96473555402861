import {z} from 'zod';

const VideoMimeTypes = ['video/x-m4v', 'video/mp4', 'video/mpeg', 'video/quicktime'] as const;
export const VideoMimeTypeSchema = z.enum(VideoMimeTypes);
export type VideoMimeType = z.infer<typeof VideoMimeTypeSchema>;

const ImageMimeTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'] as const;
export const ImageMimeTypeSchema = z.enum(ImageMimeTypes);
export type ImageMimeType = z.infer<typeof ImageMimeTypeSchema>;

const PdfMimeTypes = ['application/pdf'] as const;
export const PdfMimeTypeSchema = z.enum(PdfMimeTypes);
export type PdfMimeType = z.infer<typeof PdfMimeTypeSchema>;

export const MimeTypeSchema = z.enum([...ImageMimeTypes, ...VideoMimeTypes, ...PdfMimeTypes]);
export type MimeType = z.infer<typeof MimeTypeSchema>;

export const WalletAssetKindSchema = z.enum([
  'cohortFormImage',
  'cohortFormVideo',
  'cohortFormImageOrVideo',
]);
export type WalletAssetKind = z.infer<typeof WalletAssetKindSchema>;

export const AdminAssetKindSchema = z.enum([
  'badgeVisual',
  'banner',
  'contentImage',
  'contentVideo',
  'contentVideoThumbnail',
  'challengeVisual',
  'digitalAssetVisual',
  'cohortFormPromptVisual',
  'logo',
  'membershipPassBanner',
  'perkBanner',
  'perkVideo',
  'privateContent',
  'qrCodeSuccessImage',
  'rewardConfigurationVisual',
  'storeVisual',
  'termsAndConditions',
  'swipeCardImage',
]);
export type AdminAssetKind = z.infer<typeof AdminAssetKindSchema>;

export const AssetKindSchema = z.union([WalletAssetKindSchema, AdminAssetKindSchema]);
export type AssetKind = z.infer<typeof AssetKindSchema>;

export const AllowedAssetMimeTypes: Record<AssetKind, z.ZodEnum<[string, ...string[]]>> = {
  badgeVisual: z.enum(ImageMimeTypes),
  banner: z.enum(ImageMimeTypes),
  contentImage: z.enum(ImageMimeTypes),
  contentVideo: z.enum(VideoMimeTypes),
  contentVideoThumbnail: z.enum(ImageMimeTypes),
  challengeVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  digitalAssetVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  cohortFormPromptVisual: z.enum(ImageMimeTypes),
  logo: z.enum(ImageMimeTypes),
  membershipPassBanner: z.enum(ImageMimeTypes),
  perkBanner: z.enum(ImageMimeTypes),
  perkVideo: z.enum(VideoMimeTypes),
  privateContent: z.enum([...VideoMimeTypes, ...PdfMimeTypes]),
  qrCodeSuccessImage: z.enum(ImageMimeTypes),
  rewardConfigurationVisual: z.enum(ImageMimeTypes),
  storeVisual: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  termsAndConditions: z.enum(PdfMimeTypes),
  cohortFormImageOrVideo: z.enum([...ImageMimeTypes, ...VideoMimeTypes]),
  cohortFormImage: z.enum(ImageMimeTypes),
  cohortFormVideo: z.enum(VideoMimeTypes),
  swipeCardImage: z.enum(ImageMimeTypes),
} as const;
