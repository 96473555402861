import {InstagramCloseFriendsListConfigSchema} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import type {ConnectButtonContext} from '@cohort/wallet/apps/ConnectButton';
import {z} from 'zod';

const TriggerConfigWithMerchantUsernameSchema = z.object({
  merchantUsername: z.string(),
});

export const getMerchantConnectionIdAndUsernameFromContext = (
  context: ConnectButtonContext | undefined
): {
  merchantConnectionId: string | undefined;
  merchantUsername: string | undefined;
} => {
  let merchantConnectionId: string | undefined = undefined;
  let merchantUsername: string | undefined = undefined;

  const isTriggerContext = context?.trigger?.triggerIntegrationConfig !== undefined;
  const isPerkContext = context?.perk?.integration?.config !== undefined;

  if (isTriggerContext) {
    const triggerConfig = TriggerConfigWithMerchantUsernameSchema.safeParse(
      context.trigger?.triggerIntegrationConfig
    );
    if (triggerConfig.success) {
      merchantConnectionId = context.trigger?.connectionId ?? undefined;
      merchantUsername = triggerConfig.data.merchantUsername;
    }
  }

  if (isPerkContext) {
    const perkConfig = InstagramCloseFriendsListConfigSchema.safeParse(
      context.perk?.integration?.config
    );
    if (perkConfig.success) {
      merchantConnectionId = context.perk?.integration?.connectionId ?? undefined;
      merchantUsername = perkConfig.data.username;
    }
  }

  return {merchantConnectionId, merchantUsername};
};
