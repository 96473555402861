import TiktokEmbedPost from '@cohort/components-xps/components/apps/tiktok/TiktokEmbedPost';
import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';

const TikTokPostVisualPreview: React.FC<VisualPreviewProps<TikTokPostMediaStruct>> = ({
  config,
  title,
}) => (
  <TiktokEmbedPost
    videoId={config.videoId}
    width="100%"
    aspect="aspect-video"
    title={title}
    muted
  />
);

export default TikTokPostVisualPreview;
