import type {FeatureFlags} from '@cohort/wallet/FeatureFlags';
import {useContentsPreviewByCategories} from '@cohort/wallet/hooks/api/Content';
import {
  getChallengesListRoute,
  getContentCategoriesRoute,
  getMerchantSpaceRoute,
  getRewardsListRoute,
  getSpaceSettingsRoute,
} from '@cohort/wallet/lib/Pages';
import type {CaretLeft} from '@phosphor-icons/react';
import {Flag, GearSix, Gift, HouseLine, MonitorPlay} from '@phosphor-icons/react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

export type SpaceSection = {
  id: string;
  title: string;
  // We take a random icon from the lib to get the proper type
  icon: typeof CaretLeft;
  isSelected: boolean;
  disabled: boolean;
  path: string;
};

export default function useSpaceNavigation(): Array<SpaceSection> {
  const {t} = useTranslation('hooks', {keyPrefix: 'spaceNavigation'});
  const {featureContent} = useFlags<FeatureFlags>();
  const location = useLocation();
  // Only show the contents section if there are contents for the merchant.
  const {data: contentsWithCategories, isFetched} = useContentsPreviewByCategories(featureContent);
  const showContents = isFetched && contentsWithCategories && contentsWithCategories.length > 0;

  function isCurrentPath(href: string): boolean {
    return location.pathname === href;
  }

  return [
    {
      id: 'home',
      title: t('homePageTitle'),
      icon: HouseLine,
      isSelected: location.pathname.startsWith(getMerchantSpaceRoute()),
      disabled: isCurrentPath(getMerchantSpaceRoute()),
      path: getMerchantSpaceRoute(),
    },
    {
      id: 'challenges',
      title: t('challengesTitle'),
      icon: Flag,
      isSelected: location.pathname.startsWith(getChallengesListRoute()),
      disabled: isCurrentPath(getChallengesListRoute()),
      path: getChallengesListRoute(),
    },
    {
      id: 'rewards',
      title: t('rewardsTitle'),
      icon: Gift,
      isSelected: location.pathname.startsWith('/space/rewards'),
      disabled: isCurrentPath(getRewardsListRoute()),
      path: getRewardsListRoute(),
    },
    ...(showContents && featureContent
      ? [
          {
            id: 'contents',
            title: t('contentsTitle'),
            icon: MonitorPlay,
            isSelected: location.pathname.startsWith('/space/contents'),
            disabled: isCurrentPath(getContentCategoriesRoute()),
            path: getContentCategoriesRoute(),
          },
        ]
      : []),
    {
      id: 'settings',
      title: t('settingsTitle'),
      icon: GearSix,
      isSelected: location.pathname.startsWith(getSpaceSettingsRoute()),
      disabled: isCurrentPath(getSpaceSettingsRoute()),
      path: getSpaceSettingsRoute(),
    },
  ];
}
