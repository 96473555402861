import type {InstagramAppStruct} from '@cohort/shared/apps/instagram';
import {InstagramAppSpec} from '@cohort/shared/apps/instagram';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import InstagramCreateConnectionComponent from '@cohort/wallet/apps/instagram/connector/CreateConnectionComponent';
import {InstagramCloseFriendsListPerkIntegration} from '@cohort/wallet/apps/instagram/perkIntegrations/closeFriendsList/PerkIntegration';
import {InstagramCommentMediaTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/commentMedia/TriggerIntegration';
import {InstagramFollowTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/follow/TriggerIntegration';
import {InstagramLikeMediaTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/likeMedia/TriggerIntegration';
import {InstagramTagInPostTriggerIntegration} from '@cohort/wallet/apps/instagram/triggerIntegrations/tagInPost/TriggerIntegration';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import {t} from 'i18next';

const InstagramApp: App<InstagramAppStruct> = {
  spec: InstagramAppSpec,
  perkIntegrations: [InstagramCloseFriendsListPerkIntegration],
  triggerIntegrations: [
    InstagramFollowTriggerIntegration,
    InstagramCommentMediaTriggerIntegration,
    InstagramLikeMediaTriggerIntegration,
    InstagramTagInPostTriggerIntegration,
  ] as Array<TriggerIntegration>,
  medias: [] as Array<ContentMedia>,
  logo: <SvgAppIcon name="instagram" height={40} width={40} />,
  CreateConnectionComponent: InstagramCreateConnectionComponent,
  customConnectionErrorMessage: (cause: CohortErrorCode) => {
    switch (cause) {
      case 'app.instagram.conversation-not-found':
        return {title: t('customConnectionErrors.conversationNotFound', {ns: 'app-instagram'})};
      default:
        return undefined;
    }
  },
};

export default InstagramApp;
