import TwitterEmbedPost from '@cohort/components-xps/components/apps/twitter/TwitterEmbedPost';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {getI18n} from 'react-i18next';

const MediaContentTwitterPost: React.FC<MediaContentProps<TwitterPostMediaStruct>> = ({
  config,
  title,
  description,
  hasDarkBg,
  isMobile,
}) => {
  const lang = formatI18nLanguage(getI18n().language);

  if (hasDarkBg === undefined) {
    throw new Error('hasDarkBg is required for TwitterPost');
  }

  return (
    <div className="w-full space-y-6">
      {config?.postId && (
        <div className="-mb-2.5 w-full overflow-hidden">
          <TwitterEmbedPost
            tweetId={config.postId}
            theme={hasDarkBg ? 'dark' : 'light'}
            lang={lang}
            isMobile={isMobile}
          />
        </div>
      )}
      <div>
        <MediaContentInfos title={title} description={description} />
      </div>
    </div>
  );
};

export default MediaContentTwitterPost;
