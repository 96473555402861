import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortRewardBoxUnlockedPropertiesSchema = z.object({
  rewardBoxId: z.string().uuid(),
  campaignId: z.string().uuid().nullable(),
  campaignInternalName: z.string().nullable(),
  rewardConfigurationId: z.string().uuid(),
});
export type CohortRewardBoxUnlockedProperties = z.infer<
  typeof CohortRewardBoxUnlockedPropertiesSchema
>;

export type CohortRewardBoxUnlockedEventStruct = UserEventStruct<
  'cohort.reward-box.unlocked',
  keyof CohortRewardBoxUnlockedProperties,
  CohortRewardBoxUnlockedProperties,
  CohortRewardBoxUnlockedProperties
>;

export const CohortRewardBoxUnlockedEventSpec: UserEventSpec<CohortRewardBoxUnlockedEventStruct> = {
  id: 'cohort.reward-box.unlocked',
  name: 'Mystery Box has been unlocked',
  description: 'Event Triggered when a Mystery Box has been unlocked',
  propertiesSchema: CohortRewardBoxUnlockedPropertiesSchema,
  exposedPropertiesSchema: CohortRewardBoxUnlockedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
