import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {BazaarvoiceConnectorStruct} from '@cohort/shared/apps/bazaarvoice/connector';
import {BazaarvoiceConnectorSpec} from '@cohort/shared/apps/bazaarvoice/connector';
import {BazaarvoiceProductReviewedEventSpec} from '@cohort/shared/apps/bazaarvoice/userEvents/productReviewed';
import type {UserEventSpec} from '@cohort/shared/apps/userEvent';

export const BAZAARVOICE_APP_ID = 'bazaarvoice';
export const BAZAARVOICE_APP_NAME = 'Bazaarvoice';

export const BazaarvoiceEventSpecs = [
  BazaarvoiceProductReviewedEventSpec,
] as const satisfies ReadonlyArray<UserEventSpec>;
type BazaarvoiceEventIds = (typeof BazaarvoiceEventSpecs)[number]['id'];

export type BazaarvoiceAppStruct = AppStruct<
  'bazaarvoice',
  BazaarvoiceConnectorStruct,
  null,
  never,
  never,
  never,
  never,
  never,
  BazaarvoiceEventIds,
  never,
  never,
  never
>;

export const BazaarvoiceAppSpec: AppSpec<BazaarvoiceAppStruct> = {
  id: BAZAARVOICE_APP_ID,
  name: BAZAARVOICE_APP_NAME,
  documentationUrl: 'https://docs.getcohort.com/integrations/apps/bazaarvoice',
  merchantConnector: BazaarvoiceConnectorSpec,
  userConnector: null,
  syncSpec: null,
  actionSpecs: [],
  notificationIntegrationSpecs: [],
  perkIntegrationSpecs: [],
  triggerIntegrationSpecs: [],
  userEventSpecs: BazaarvoiceEventSpecs,
  userPropertySpecs: [],
  resourceSpecs: [],
  mediaSpecs: [],
};
