import type {MediaSpec, MediaStruct} from '@cohort/shared/apps/media';
import {z} from 'zod';

export const InstagramPostMediaConfigSchema = z.object({
  mediaId: z.string(),
  mediaPermalink: z.string().url(),
});
export type InstagramPostMediaConfig = z.infer<typeof InstagramPostMediaConfigSchema>;

export type InstagramPostMediaStruct = MediaStruct<'instagram.post', InstagramPostMediaConfig>;
export const InstagramPostMediaSpec: MediaSpec<InstagramPostMediaStruct> = {
  kind: 'instagram.post',
  configSchema: InstagramPostMediaConfigSchema,
};
