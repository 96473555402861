import type {ConnectorId} from '@cohort/shared/apps';
import type {ActionStruct, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import type {WalletAssetKind} from '@cohort/shared/schema/common/assets';
import type {PaginationDto} from '@cohort/shared/schema/common/pagination';
import {paginatedParser} from '@cohort/shared/utils/parser';
import {apiCall, HttpCodes} from '@cohort/wallet/lib/Api';
import {parseJwtToken} from '@cohort/wallet/lib/Parsers';
import type {
  ExecuteActionDataWDto,
  ExecuteActionResponseWDto,
  ExecuteAsyncActionDataWDto,
} from '@cohort/wallet-schemas/apps';
import {ExecuteActionResponseWSchema} from '@cohort/wallet-schemas/apps';
import type {SignedUrlWDto} from '@cohort/wallet-schemas/asset';
import {SignedUrlWSchema} from '@cohort/wallet-schemas/asset';
import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import type {StandaloneChallengeWDto} from '@cohort/wallet-schemas/campaign';
import {CampaignStoreWSchema} from '@cohort/wallet-schemas/campaign';
import {StandaloneChallengeWSchema} from '@cohort/wallet-schemas/campaign';
import type {
  ChallengeParticipationWDto,
  VerifyChallengeStepWDto,
} from '@cohort/wallet-schemas/challengeParticipation';
import {ChallengeParticipationWSchema} from '@cohort/wallet-schemas/challengeParticipation';
import type {
  ConnectionWDto,
  CreateCustomConnectionDataWDto,
  GetAuthorizationUrlDataWDto,
  GetAuthorizationUrlResponseWDto,
  GetConnectionResponseWDto,
  GetConnectionsResponseWDto,
} from '@cohort/wallet-schemas/connections';
import {
  ConnectionWSchema,
  GetConnectionResponseWSchema,
  GetConnectionsResponseWSchema,
} from '@cohort/wallet-schemas/connections';
import {GetAuthorizationUrlResponseWSchema} from '@cohort/wallet-schemas/connections';
import type {
  ContentCategoryWDto,
  ContentsPreviewByCategoriesWDto,
  ContentWDto,
  GetPaginatedContentsParamsWDto,
} from '@cohort/wallet-schemas/content';
import {
  ContentCategoryWSchema,
  ContentsPreviewByCategoriesWSchema,
  ContentWSchema,
} from '@cohort/wallet-schemas/content';
import type {MerchantInitWDto} from '@cohort/wallet-schemas/merchant';
import {MerchantInitWSchema} from '@cohort/wallet-schemas/merchant';
import type {
  FindOrderByPaymentSessionIdResponseWDto,
  OrderWDto,
  PaymentSessionWDto,
} from '@cohort/wallet-schemas/order';
import {
  FindOrderByPaymentSessionIdResponseWSchema,
  OrderWSchema,
  PaymentSessionWSchema,
} from '@cohort/wallet-schemas/order';
import type {
  ConfirmTransferWDto,
  DigitalAssetTransferInvitationWDto,
  DigitalAssetTransferJobWDto,
  OwnershipWDto,
  RequestOwnershipTransferDataWDto,
} from '@cohort/wallet-schemas/ownership';
import {
  ConfirmTransferWSchema,
  DigitalAssetTransferInvitationWSchema,
  DigitalAssetTransferJobWSchema,
  OwnershipWSchema,
} from '@cohort/wallet-schemas/ownership';
import type {PerkAccessWDto, PerkPrivateContentUrlWDto} from '@cohort/wallet-schemas/perkAccess';
import {PerkAccessWSchema, PerkPrivateContentUrlWSchema} from '@cohort/wallet-schemas/perkAccess';
import type {PerkUsageInputWDto, PerkUsageWDto} from '@cohort/wallet-schemas/perkUsage';
import {PerkUsageWSchema} from '@cohort/wallet-schemas/perkUsage';
import type {RewardBoxRewardsWDto} from '@cohort/wallet-schemas/reward';
import {RewardBoxRewardsWSchema} from '@cohort/wallet-schemas/reward';
import type {SpaceWDto, SyncSpaceResultWDto} from '@cohort/wallet-schemas/space';
import {SpaceWSchema, SyncSpaceResultWSchema} from '@cohort/wallet-schemas/space';
import type {PatchUserWDto, UserWDto} from '@cohort/wallet-schemas/user';
import {UserWSchema} from '@cohort/wallet-schemas/user';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import {ListUserAttributesResponseWSchema} from '@cohort/wallet-schemas/userAttributes';

export interface Endpoint<T> {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  url: string;
  parser?: (data: unknown) => T;
  body?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  headers?: Record<string, string>;
}

export async function executeAppAction<T extends ActionStruct = ActionStruct>(
  data: ExecuteActionDataWDto<T>
): Promise<ExecuteActionResponseWDto<T>> {
  return apiCall('POST', '/v1/apps/execute-action', {
    expect: HttpCodes.CREATED,
    parser: ExecuteActionResponseWSchema.parse as (data: unknown) => ExecuteActionResponseWDto<T>,
    body: data,
  });
}

export async function executeAsyncAppAction<T extends ActionStruct = ActionStruct>(
  data: ExecuteAsyncActionDataWDto<T>
): Promise<void> {
  return apiCall('POST', '/v1/apps/execute-async-action', {
    expect: HttpCodes.CREATED,
    parser: () => {},
    body: data,
  });
}

export async function getConnections(): Promise<GetConnectionsResponseWDto> {
  return apiCall('GET', '/v1/connections', {
    expect: HttpCodes.SUCCESS,
    parser: GetConnectionsResponseWSchema.parse,
  });
}

export async function getConnectionByConnectorId(
  connectorId: ConnectorId
): Promise<GetConnectionResponseWDto> {
  return apiCall('GET', `/v1/connections/connector/${connectorId}`, {
    expect: HttpCodes.SUCCESS,
    parser: GetConnectionResponseWSchema.parse,
  });
}

export async function getOauthAuthorizationUrl(
  data: GetAuthorizationUrlDataWDto
): Promise<GetAuthorizationUrlResponseWDto> {
  return apiCall('POST', '/v1/connections/oauth-authorization-url', {
    expect: HttpCodes.CREATED,
    parser: GetAuthorizationUrlResponseWSchema.parse,
    body: {
      connectorId: data.connectorId,
      embedUrl: data.embedUrl,
      xpsRedirectUrl: data.xpsRedirectUrl,
      existingConnectionId: data.existingConnectionId,
    },
  });
}

export async function deleteConnection(connectionId: string): Promise<void> {
  return apiCall('DELETE', `/v1/connections/${connectionId}`, {
    expect: HttpCodes.SUCCESS,
    parser: () => {},
    body: {},
  });
}

export async function createCustomConnection(
  body: CreateCustomConnectionDataWDto
): Promise<ConnectionWDto> {
  return apiCall('POST', '/v1/connections', {
    expect: HttpCodes.CREATED,
    parser: ConnectionWSchema.parse,
    body,
  });
}

export async function logIn(headers: HeadersInit): Promise<UserWDto> {
  return apiCall('POST', '/v1/user/login', {
    expect: HttpCodes.CREATED,
    parser: UserWSchema.parse,
    headers,
  });
}

export async function deleteUser(): Promise<void> {
  return apiCall('DELETE', '/v1/user', {
    expect: HttpCodes.SUCCESS,
    parser: () => {},
    body: {},
  });
}

export async function patchUser(data: PatchUserWDto): Promise<UserWDto> {
  return apiCall('PATCH', '/v1/user', {
    expect: HttpCodes.SUCCESS,
    parser: UserWSchema.parse,
    body: data,
  });
}

export async function sendSigninCode(
  email: string,
  merchantSlug: string,
  turnstileToken: string
): Promise<void> {
  return apiCall('POST', '/v1/user/send-signin-code-email', {
    expect: HttpCodes.CREATED,
    parser: () => {},
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    body: {
      email,
      merchantSlug,
    },
  });
}

export async function verifyOtpCode(
  merchantSlug: string,
  email: string,
  code: string,
  turnstileToken: string
): Promise<string> {
  return apiCall('POST', '/v1/user/verify-otp', {
    expect: HttpCodes.CREATED,
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    parser: parseJwtToken,
    body: {
      merchantSlug,
      email,
      code,
    },
  });
}

export async function identifyUser(
  merchantSlug: string,
  email: string,
  turnstileToken: string
): Promise<string> {
  return apiCall('POST', '/v1/user/identify', {
    expect: HttpCodes.CREATED,
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    parser: parseJwtToken,
    body: {
      merchantSlug,
      email,
    },
  });
}

export async function verifyAuthToken(authToken: string): Promise<string> {
  return apiCall('POST', '/v1/user/verify-auth-token', {
    expect: HttpCodes.CREATED,
    parser: parseJwtToken,
    body: {authToken},
  });
}

export async function getStoreBySlug(
  merchantId: string,
  campaignSlug: string,
  redeemCode?: string
): Promise<CampaignStoreWDto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/stores/${campaignSlug}`, {
    expect: HttpCodes.SUCCESS,
    parser: CampaignStoreWSchema.parse,
    params: {redeemCode},
  });
}

export async function getChallengeBySlug(
  merchantId: string,
  challengeSlug: string
): Promise<StandaloneChallengeWDto> {
  return apiCall('GET', `/v1/merchants/${merchantId}/challenges/${challengeSlug}`, {
    expect: HttpCodes.SUCCESS,
    parser: StandaloneChallengeWSchema.parse,
  });
}

export async function createPaymentSession(
  campaignSlug: string,
  currency: string,
  redeemCode: string | undefined
): Promise<PaymentSessionWDto> {
  return apiCall('POST', `/v1/campaigns/${campaignSlug}/create-payment-session`, {
    expect: HttpCodes.CREATED,
    parser: PaymentSessionWSchema.parse,
    body: {redeemCode, currency},
  });
}

export async function createFreeOrder(
  campaignId: string,
  redeemCode: string | undefined
): Promise<OrderWDto> {
  return apiCall('POST', '/v1/orders', {
    expect: HttpCodes.CREATED,
    parser: OrderWSchema.parse,
    body: {campaignId, redeemCode},
  });
}

export async function getOrder(orderId: string): Promise<OrderWDto> {
  return apiCall('GET', `/v1/orders/${orderId}`, {
    expect: HttpCodes.SUCCESS,
    parser: OrderWSchema.parse,
  });
}

export async function findOrderByPaymentSessionId(
  paymentSessionId: string
): Promise<FindOrderByPaymentSessionIdResponseWDto> {
  return apiCall('GET', '/v1/orders', {
    expect: HttpCodes.SUCCESS,
    parser: FindOrderByPaymentSessionIdResponseWSchema.parse,
    params: {paymentSessionId},
  });
}

export async function getMerchantSpace(): Promise<SpaceWDto> {
  return apiCall('GET', '/v1/space', {
    expect: HttpCodes.SUCCESS,
    parser: SpaceWSchema.parse,
  });
}

export async function syncSpaceOwnerships(): Promise<SyncSpaceResultWDto> {
  return apiCall('POST', '/v1/space/sync', {
    expect: HttpCodes.CREATED,
    parser: SyncSpaceResultWSchema.parse,
  });
}

export async function getContent(contentId: string): Promise<ContentWDto> {
  return apiCall('GET', `/v1/contents/${contentId}`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentWSchema.parse,
  });
}

export async function getContentsPreviewByCategories(): Promise<ContentsPreviewByCategoriesWDto> {
  return apiCall('GET', '/v1/contents/preview', {
    expect: HttpCodes.SUCCESS,
    parser: ContentsPreviewByCategoriesWSchema.parse,
  });
}

export async function getContentCategory(categorySlug: string): Promise<ContentCategoryWDto> {
  return apiCall('GET', `/v1/contents/category/${categorySlug}`, {
    expect: HttpCodes.SUCCESS,
    parser: ContentCategoryWSchema.parse,
  });
}

export async function getPaginatedContents(
  params: GetPaginatedContentsParamsWDto
): Promise<[PaginationDto, Array<ContentWDto>]> {
  return apiCall('GET', '/v1/contents', {
    expect: HttpCodes.SUCCESS,
    parser: paginatedParser(ContentWSchema) as (
      data: unknown
    ) => [PaginationDto, Array<ContentWDto>],
    params,
  });
}

export async function getNftTransferInvitation(
  invitationId: string
): Promise<DigitalAssetTransferInvitationWDto> {
  return apiCall('GET', `/v1/invitations/${invitationId}`, {
    expect: HttpCodes.SUCCESS,
    parser: DigitalAssetTransferInvitationWSchema.parse,
  });
}

export async function acceptDigitalAssetTransferInvitation(
  invitationId: string
): Promise<DigitalAssetTransferJobWDto> {
  return apiCall('POST', `/v1/invitations/${invitationId}/accept`, {
    expect: HttpCodes.CREATED,
    parser: DigitalAssetTransferJobWSchema.parse,
  });
}

export async function getPerkAccess(perkAccessId: string): Promise<PerkAccessWDto> {
  return apiCall('GET', `/v1/perk-accesses/${perkAccessId}`, {
    expect: HttpCodes.SUCCESS,
    parser: PerkAccessWSchema.parse,
  });
}

export async function generatePrivateContentUrl(
  perkAccessId: string
): Promise<PerkPrivateContentUrlWDto> {
  return apiCall('POST', `/v1/perk-accesses/${perkAccessId}/generate-private-content-url`, {
    expect: HttpCodes.CREATED,
    parser: PerkPrivateContentUrlWSchema.parse,
  });
}

export async function activatePerkAccess(perkAccessId: string): Promise<PerkAccessWDto> {
  return apiCall('POST', `/v1/perk-accesses/${perkAccessId}/activate`, {
    expect: HttpCodes.CREATED,
    parser: PerkAccessWSchema.parse,
  });
}

export async function doUsePerkAccess<T extends PerkIntegrationStruct = PerkIntegrationStruct>(
  perkAccessId: string,
  userConnectionId: string | null,
  usageData: T['UsageInputData']
): Promise<PerkUsageWDto> {
  return apiCall('POST', `/v1/perk-accesses/${perkAccessId}/use`, {
    expect: HttpCodes.CREATED,
    parser: PerkUsageWSchema.parse,
    body: {data: usageData, userConnectionId} satisfies PerkUsageInputWDto,
  });
}

export async function getOwnership(ownershipId: string): Promise<OwnershipWDto> {
  return apiCall('GET', `/v1/ownerships/${ownershipId}`, {
    expect: HttpCodes.SUCCESS,
    parser: OwnershipWSchema.parse,
  });
}

export async function getNftTransferJob(transferId: string): Promise<DigitalAssetTransferJobWDto> {
  return apiCall('GET', `/v1/transfers/${transferId}`, {
    expect: HttpCodes.SUCCESS,
    parser: DigitalAssetTransferJobWSchema.parse,
  });
}

export async function cancelNftTransferInvitation(
  ownershipId: string,
  invitationId: string
): Promise<OwnershipWDto> {
  return apiCall('POST', `/v1/ownerships/${ownershipId}/invitations/${invitationId}/cancel`, {
    expect: HttpCodes.CREATED,
    parser: OwnershipWSchema.parse,
  });
}

export async function confirmOwnershipTransfer(
  ownershipId: string | undefined,
  validationCode: string | null
): Promise<ConfirmTransferWDto> {
  return apiCall('POST', `/v1/ownerships/${ownershipId}/confirm-transfer`, {
    expect: HttpCodes.CREATED,
    parser: ConfirmTransferWSchema.parse,
    body: {validationCode},
  });
}

export async function requestOwnershipTransfer(
  ownershipId: string,
  data: RequestOwnershipTransferDataWDto
): Promise<object> {
  return apiCall('POST', `/v1/ownerships/${ownershipId}/request-transfer`, {
    expect: HttpCodes.CREATED,
    parser: () => ({}),
    body: data,
  });
}

export async function getMerchantBySlug(slug: string): Promise<MerchantInitWDto> {
  return apiCall('GET', `/v1/merchants/slug/${slug}`, {
    expect: HttpCodes.SUCCESS,
    parser: MerchantInitWSchema.parse,
  });
}

export async function joinChallengeCampaign(
  campaignId: string
): Promise<ChallengeParticipationWDto> {
  return apiCall('POST', '/v1/challenge-participations', {
    expect: HttpCodes.CREATED,
    parser: ChallengeParticipationWSchema.parse,
    body: {campaignId},
  });
}

export async function completeChallenge(
  challengeParticipationId: string
): Promise<ChallengeParticipationWDto> {
  return apiCall('POST', `/v1/challenge-participations/${challengeParticipationId}/complete`, {
    expect: HttpCodes.CREATED,
    parser: ChallengeParticipationWSchema.parse,
  });
}

export async function verifyChallengeStep(
  challengeParticipationId: string,
  body: VerifyChallengeStepWDto
): Promise<ChallengeParticipationWDto> {
  return apiCall('POST', `/v1/challenge-participations/${challengeParticipationId}/verify-step`, {
    expect: HttpCodes.CREATED,
    parser: ChallengeParticipationWSchema.parse,
    body,
  });
}

export async function listUserAttributes(
  userPropertyIds: Array<string>
): Promise<Array<UserAttributeWDto>> {
  return apiCall('GET', '/v1/user-attributes', {
    expect: HttpCodes.SUCCESS,
    parser: ListUserAttributesResponseWSchema.parse,
    params: {userPropertyIds},
  });
}

export async function getSignedUrl(
  assetKind: WalletAssetKind,
  mimeType: string
): Promise<SignedUrlWDto> {
  return apiCall('GET', `/v1/assets/upload-signed-url`, {
    expect: HttpCodes.SUCCESS,
    parser: SignedUrlWSchema.parse,
    params: {assetKind, mimeType},
  });
}

export async function getRewardBoxRewards(rewardBoxId: string): Promise<RewardBoxRewardsWDto> {
  return apiCall('GET', `/v1/reward-boxes/${rewardBoxId}/rewards`, {
    expect: HttpCodes.SUCCESS,
    parser: RewardBoxRewardsWSchema.parse,
    params: {rewardBoxId},
  });
}

export async function openRewardBox(rewardBoxId: string): Promise<RewardBoxRewardsWDto> {
  return apiCall('POST', `/v1/reward-boxes/${rewardBoxId}/open`, {
    expect: HttpCodes.CREATED,
    parser: RewardBoxRewardsWSchema.parse,
    params: {rewardBoxId},
  });
}
