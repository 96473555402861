import EmbedError from '@cohort/components-xps/components/apps/EmbedError';
import EmbedSkeleton from '@cohort/components-xps/components/apps/EmbedSkeleton';
import type {TwitterSupportedLanguage} from '@cohort/components-xps/components/apps/twitter/utils';
import {mapCohortToTwitterLanguages} from '@cohort/components-xps/components/apps/twitter/utils';
import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {Language} from '@cohort/shared/schema/common';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {loadScript} from '@cohort/shared-frontend/utils/loadScript';
import React, {useEffect, useRef, useState, useSyncExternalStore} from 'react';
import {getI18n, useTranslation} from 'react-i18next';

type TwitterEmbedProps = {
  lang: Language;
  tweetId: string;
  theme?: 'light' | 'dark';
  conversation?: 'none' | 'all';
  borderRadius?: string;
  isMobile?: boolean;
};

declare global {
  interface Window {
    twttr?: {
      ready: (callback: () => void) => void;
      widgets: {
        createTweet: (
          tweetId: string,
          targetEl: HTMLElement,
          options?: {
            theme?: 'light' | 'dark';
            conversation?: 'none' | 'all';
            lang?: TwitterSupportedLanguage;
          }
        ) => Promise<HTMLElement>;
      };
    };
  }
}

// Shared state for API readiness
let isTwitterApiReady = false;
const twitterApiListeners = new Set<() => void>();

function subscribe(callback: () => void): () => void {
  twitterApiListeners.add(callback);
  return () => {
    twitterApiListeners.delete(callback);
  };
}

function getSnapshot(): boolean {
  return isTwitterApiReady;
}

function getServerSnapshot(): boolean {
  return false;
}

function useTwitterApi(): {isApiReady: boolean; isApiError: boolean} {
  const isApiReady = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  const [isApiError, setIsApiError] = useState(false);

  useEffect(() => {
    if (isTwitterApiReady) {
      return;
    }

    loadScript('https://platform.twitter.com/widgets.js')
      .then(() => {
        if (window.twttr?.widgets) {
          isTwitterApiReady = true;
          twitterApiListeners.forEach(listener => listener());
          twitterApiListeners.clear();
        }
      })
      .catch(() => setIsApiError(true));
  }, []);

  return {isApiReady, isApiError};
}

const TwitterEmbedPost: React.FC<TwitterEmbedProps> = ({
  tweetId,
  theme = 'light',
  conversation = 'none',
  lang = 'en',
  borderRadius,
  isMobile,
}) => {
  const {isApiReady, isApiError} = useTwitterApi();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation('components', {
    keyPrefix: 'apps.twitter.twitterEmbedPost',
    lng: getI18n().language,
    i18n: i18nComponentsXpsInstance,
  });

  // Reset the container when the tweetId changes.
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }
    setIsLoading(true);
    setIsError(false);
  }, [tweetId]);

  useEffect(() => {
    if (isApiReady && containerRef.current && window.twttr?.widgets) {
      const currentContainer = containerRef.current;

      window.twttr.widgets
        .createTweet(tweetId, currentContainer, {
          theme,
          conversation,
          lang: mapCohortToTwitterLanguages(lang),
        })
        .then(tweetHtmlElement => {
          if (currentContainer !== containerRef.current) {
            // If the component re-rendered, avoid updating state for a stale ref
            return;
          }
          if (currentContainer.childNodes.length > 1) {
            // If the container already contains a tweet, we don't want to update the state.
            currentContainer.innerHTML = tweetHtmlElement.outerHTML;
            setIsLoading(false);
            setIsError(false);
          } else {
            setIsLoading(false);
            setIsError(false);
          }
        })
        .catch(err => {
          if (currentContainer !== containerRef.current) {
            return; // Avoid stale ref issues
          }
          // eslint-disable-next-line no-console
          console.error('Error embedding tweet:', err);
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [isApiReady, tweetId, theme]);

  return (
    <div
      className={cn(
        'h-fit',
        borderRadius ?? '!rounded-[--xps-img-border-radius]',
        // @Devs - Remove extra margin on mobile added by the twitter widget.
        // There is not way to enfore a height: https://developer.x.com/en/docs/x-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference
        isMobile && !isLoading && '-mb-2'
      )}
    >
      <div
        ref={containerRef}
        style={{marginTop: '-10px'}} // Default negative margin returned by twitter.
        className={cn(
          'h-fit',
          borderRadius ?? 'rounded-[--xps-img-border-radius]',
          isLoading && '!h-0'
        )}
      />
      {isLoading && (
        <div className="overflow-hidden !rounded-[--xps-img-border-radius]">
          <EmbedSkeleton width="100%" height={200} />
        </div>
      )}
      {(isApiError || isError) && <EmbedError error={t('twitterEmbedError')} />}
    </div>
  );
};

export default TwitterEmbedPost;
