import type {ConnectorSpec, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import type {OAuth2AppCredentials} from '@cohort/shared/apps/oauth';
import {z} from 'zod';

export const InstagramUserCredentialsSchema = z.object({
  username: z.string(),
  verifiedWithMerchantConnectionId: z.string(),
  verificationMessage: z.string().optional(),
});
export type InstagramUserCredentials = z.infer<typeof InstagramUserCredentialsSchema>;

export const InstagramUserProfileSchema = BaseProfilePropertiesSchema.extend({
  username: z.string(),
});
export type InstagramUserProfile = z.infer<typeof InstagramUserProfileSchema>;

export type InstagramUserConnectorStruct = ConnectorStruct<
  'instagram',
  OAuth2AppCredentials,
  InstagramUserCredentials,
  InstagramUserProfile,
  null
>;

export const InstagramUserConnectorSpec = {
  id: 'instagram',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: InstagramUserCredentialsSchema,
  profilePropertiesSchema: InstagramUserProfileSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<InstagramUserConnectorStruct>;
