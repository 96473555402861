import i18nComponentsXpsInstance from '@cohort/components-xps/lib/i18n';
import type {Language} from '@cohort/shared/schema/common';
import {WebappLanguageSchema} from '@cohort/shared/schema/common';
import {formatI18nLanguage} from '@cohort/shared/utils/localization';
import {Apps} from '@cohort/wallet/apps';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {patchUser} from '@cohort/wallet/lib/Endpoints';
import {initI18n} from '@cohort/wallet/lib/i18n';
import {useQueryClient} from '@tanstack/react-query';
import dayjs from 'dayjs';
import {changeLanguage} from 'i18next';
import {useCallback, useEffect, useRef, useState} from 'react';
import {getI18n} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {shallow} from 'zustand/shallow';

const DEFAULT_LANGUAGE = 'en';

export const useLocalization = (): void => {
  const merchant = useMerchantContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const {user, isAuthenticating, setUserLanguage} = useUserStore(
    store => ({
      user: store.user,
      isAuthenticating: store.isAuthenticating,
      setUserLanguage: store.setUserLanguage,
    }),
    shallow
  );
  const {localizationLoaded, setLocalizationLoaded} = useUserStore(
    store => ({
      localizationLoaded: store.localizationLoaded,
      setLocalizationLoaded: store.setLocalizationLoaded,
    }),
    shallow
  );
  const [lngParamLoaded, setLngParamLoaded] = useState(
    searchParams.get('lng') !== null ? false : true
  );
  const previousLanguage = useRef<Language | null>(null);
  const {mutate: updateUserLang} = useCohortMutation({
    mutationFn: patchUser,
    onSuccess: user => {
      setUserLanguage(user.lang);
      setLngParamLoaded(true);
    },
  });

  const isLangSupported = useCallback(
    (language: string): language is Language =>
      merchant.supportedLanguages.some(lang => lang === language),
    [merchant.supportedLanguages]
  );

  const setLanguage = useCallback(
    async (language: Language): Promise<void> => {
      // In terms of translations, we only officially supports en, fr and es
      const i18nlanguage = WebappLanguageSchema.safeParse(language).success
        ? language
        : DEFAULT_LANGUAGE;
      const translationResource = (await import(`../lib/locales/${i18nlanguage}.json`)).default;

      // Load global translations
      Object.keys(translationResource).forEach(ns => {
        getI18n().addResourceBundle(i18nlanguage, ns, translationResource[ns]);
      });
      const appTranslationResources = await Promise.all(
        Apps.map(app => import(`../apps/${app.spec.id}/locales/${i18nlanguage}.json`))
      );

      // Load app translations
      // i18nOwl-ignore [common.badges, common.digitalAssets, common.home, common.challenges, common.contents, common.perks, common.rewards]
      appTranslationResources.forEach((appTranslationResource, index) => {
        const ns = `app-${Apps[index]?.spec.id}`;

        getI18n().addResourceBundle(i18nlanguage, ns, appTranslationResource.default);
      });

      // Load custom translations for the merchant and test merchant
      const merchantName = merchant.slug.replace(/-test$/u, '');
      const res = await fetch(
        `https://storage.googleapis.com/${
          import.meta.env.COHORT_FIREBASE_STORAGE_BUCKET
        }/translations/${merchantName}/${i18nlanguage}.json`
      );

      if (res.ok) {
        const customTranslations = await res.json();

        Object.keys(customTranslations).forEach(ns => {
          getI18n().addResourceBundle(i18nlanguage, ns, customTranslations[ns], true, true);
        });
      }

      if (language !== getI18n().language || language !== i18nComponentsXpsInstance.language) {
        dayjs.locale(language);
        changeLanguage(language);
        i18nComponentsXpsInstance.changeLanguage(language);
        localStorage.setItem('lng-wallet', language);
        // We wait for queries invalidations before removing the loader to avoid translations flickering
        await queryClient.invalidateQueries();
      }
      setLngParamLoaded(true);
      setLocalizationLoaded(true);
    },
    [queryClient, merchant.slug, setLocalizationLoaded]
  );

  // Look for lng param in url and update user language if logged in
  // If there's a user, user language is the single source of truth
  useEffect(() => {
    if (isAuthenticating) {
      return;
    }
    const lngParam = searchParams.get('lng');

    if (lngParam) {
      searchParams.delete('lng');
      setSearchParams(searchParams, {replace: true});
    }

    if (!lngParamLoaded && lngParam && isLangSupported(lngParam)) {
      if (user) {
        updateUserLang({lang: lngParam});
        return;
      }
      setLanguage(lngParam);
      return;
    }
    setLngParamLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Do not depend on searchParams.
  }, [isAuthenticating]);

  // Initialize i18n fallback with merchant default language if english is not supported
  useEffect(() => {
    if (!merchant.supportedLanguages.some(lang => lang === DEFAULT_LANGUAGE)) {
      initI18n({fallbackLng: merchant.defaultLanguage});
      i18nComponentsXpsInstance.init({fallbackLng: merchant.defaultLanguage});
      i18nComponentsXpsInstance.changeLanguage(merchant.defaultLanguage);
    }
  }, [merchant]);

  // Apply appropriate language and load translations
  // Wait for authentication to finish to ensure priority 1 is respected
  // Wait for lngParamLoaded to ensure user language is set to avoid setting language twice
  useEffect(() => {
    if (isAuthenticating || !lngParamLoaded) {
      return;
    }

    // priority 1: user logged in
    // Store the previous language to avoid setting the same language twice
    if (user && previousLanguage.current !== user.lang) {
      setLanguage(user.lang);
      previousLanguage.current = user.lang;
      return;
    }

    if (localizationLoaded) {
      return;
    }

    // priority 2: local storage - the use case is when the user is visiting a page unlogged
    // but the lng param had been set in the URL. This way we can persist it
    const storageLang = localStorage.getItem('lng-wallet');
    if (storageLang !== null && isLangSupported(storageLang)) {
      setLanguage(storageLang);
      return;
    }

    // priority 3: navigator settings
    const browserLanguage = formatI18nLanguage(navigator.language);
    if (isLangSupported(browserLanguage)) {
      setLanguage(browserLanguage);
      return;
    }

    // priority 4: english
    if (isLangSupported(DEFAULT_LANGUAGE)) {
      setLanguage(DEFAULT_LANGUAGE);
      return;
    }

    // priority 5: merchant default
    setLanguage(merchant.defaultLanguage);
  }, [
    merchant,
    setLanguage,
    user,
    isAuthenticating,
    lngParamLoaded,
    localizationLoaded,
    isLangSupported,
  ]);
};
