import {LanguageSchema, LocalizedStringSchema} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortFormPromptTypeSchema = z.enum([
  'checkbox',
  'date',
  'email',
  'long-text',
  'media',
  'number',
  'text',
  'select',
  'score',
]);
export type CohortFormPromptType = z.infer<typeof CohortFormPromptTypeSchema>;

export const CohortFormMediaTypeSchema = z.enum(['image', 'video', 'imageOrVideo']);
export type CohortFormMediaType = z.infer<typeof CohortFormMediaTypeSchema>;

export const CohortFormOptionSchema = z.object({
  value: z.string().min(1),
  label: z.record(LanguageSchema, z.string().min(1).or(z.null())),
});
export type CohortFormOption = z.infer<typeof CohortFormOptionSchema>;

export const BaseCohortFormPromptSchema = z.object({
  id: z.string().uuid(),
  name: LocalizedStringSchema,
  referenceId: z.string().min(1).nullable(),
  imageFileKey: z.string().nullable(),
  mandatory: z.boolean(),
  userPropertyId: z.string().uuid().nullable(),
});

export const MediaCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('media'),
  mediaType: CohortFormMediaTypeSchema,
});
export type MediaCohortFormPrompt = z.infer<typeof MediaCohortFormPromptSchema>;

const RestCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: CohortFormPromptTypeSchema.exclude(['media', 'select']),
});

export const SelectCohortFormPromptSchema = BaseCohortFormPromptSchema.extend({
  type: z.literal('select'),
  multipleChoice: z.boolean(),
  options: z.array(CohortFormOptionSchema).nullable(),
})
  .transform(data => {
    if (Array.isArray(data.options) && data.options.length === 0) {
      data.options = null;
    }
    return data;
  })
  .superRefine(({options}, ctx: z.RefinementCtx) => {
    if (options === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'options must be an array',
        path: ['options'],
      });
    }
  });
export type SelectCohortFormPrompt = z.infer<typeof SelectCohortFormPromptSchema>;

export const CohortFormPromptSchema = z.union([
  MediaCohortFormPromptSchema,
  RestCohortFormPromptSchema,
  SelectCohortFormPromptSchema,
]);

export type CohortFormPrompt = z.infer<typeof CohortFormPromptSchema>;

// Schema for the config of the cohort form
export const CohortFormConfigSchema = z.object({
  ctaText: LocalizedStringSchema.optional(),
  prompts: z.array(CohortFormPromptSchema).min(1, {message: 'errorRequired'}),
});
export type CohortFormConfig = z.infer<typeof CohortFormConfigSchema>;

export const CohortFormAnswerSchema = z.union([
  z.boolean(),
  z.number(),
  z.date(),
  z.string(),
  z.null(),
  z.array(z.string()),
]);

export type CohortFormAnswer = z.infer<typeof CohortFormAnswerSchema>;

export const CohortFormAnswersSchema = z.record(z.string(), CohortFormAnswerSchema);
export type CohortFormAnswers = z.infer<typeof CohortFormAnswersSchema>;
