/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {listUserAttributes} from '@cohort/wallet/lib/Endpoints';

export const userAttributesKeys = {
  userAttributes: ['userAttributes'] as const,
  listUserAttributes: (userPropertyIds: Array<string>) =>
    [...userAttributesKeys.userAttributes, userPropertyIds] as const,
};

export const useListUserAttributes = (userPropertyIds: Array<string>) => {
  return useCohortQuery({
    queryKey: userAttributesKeys.listUserAttributes(userPropertyIds),
    queryFn: async () => listUserAttributes(userPropertyIds),
  });
};
