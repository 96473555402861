import {z} from 'zod';

export const SftpCredentialsSchema = z.object({
  host: z.string(),
  port: z.number(),
  username: z.string(),
  password: z.string(),
  // TODO: Password can be made optional and replaced with SSH key
});
export type SftpCredentials = z.infer<typeof SftpCredentialsSchema>;
