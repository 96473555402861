import 'dayjs/locale/fr';

import ErrorBoundaryComponent from '@cohort/wallet/components/error-pages/ErrorBoundary';
import {LoadingScreen} from '@cohort/wallet/components/layout/LoadingScreen';
import useAuthentication from '@cohort/wallet/hooks/authentication';
import useEmbed from '@cohort/wallet/hooks/embed';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useGeolocation} from '@cohort/wallet/hooks/useGeolocation';
import {useLocalization} from '@cohort/wallet/hooks/useLocalization';
import {useScreenSize} from '@cohort/wallet/hooks/useScreenSize';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import useVersionChecker from '@cohort/wallet/hooks/versionChecker';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import {Outlet} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {shallow} from 'zustand/shallow';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

const App: React.FC = () => {
  useEmbed();
  useAuthentication();
  useVersionChecker();
  useGeolocation();
  useLocalization();

  const {isMobile} = useScreenSize();
  const {isAuthenticating, localizationLoaded} = useUserStore(
    store => ({
      isAuthenticating: store.isAuthenticating,
      localizationLoaded: store.localizationLoaded,
    }),
    shallow
  );
  const {themeLoaded} = useThemeContext();

  if (!themeLoaded || !localizationLoaded || isAuthenticating) {
    return <LoadingScreen />;
  }

  return (
    <ErrorBoundaryComponent>
      <Outlet />
      <ToastContainer
        key={`toast-container-${isMobile ? 'mobile' : 'desktop'}`}
        toastClassName={!isMobile ? '!rounded-lg' : undefined}
        position={isMobile ? 'bottom-center' : 'bottom-right'}
        autoClose={2000}
        pauseOnFocusLoss={false}
      />
    </ErrorBoundaryComponent>
  );
};

export default App;
