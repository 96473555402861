import type {AssetKind, WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import type {AssetMinDimension, SizedAssetKind} from '@cohort/shared/utils/fileUploads';
import {ASSETS_MIN_DIMENSIONS} from '@cohort/shared/utils/fileUploads';
import {getImageUrl, getVideoUrl, Sizes} from '@cohort/shared/utils/media';
import VideoPlayer from '@cohort/shared-frontend/components/VideoPlayer';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {getFileType} from '@cohort/shared-frontend/utils/fileUploads';
import {isEmptyFileList, isFile} from '@cohort/shared-frontend/utils/isFile';
import Button from '@cohort/wallet/components/button/Button';
import type {FieldWrapperProps} from '@cohort/wallet/components/forms/FieldWrapper';
import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import useFileInputContext from '@cohort/wallet/components/forms/fileInput/useFileInputContext';
import type {FormField} from '@cohort/wallet/components/forms/fileInput/utils';
import {isValidFile} from '@cohort/wallet/components/forms/fileInput/utils';
import {CameraPlus, X} from '@phosphor-icons/react';
import {CheckCircle, Image, VideoCamera} from '@phosphor-icons/react';
import React, {createContext} from 'react';
import type {FieldValues} from 'react-hook-form';
import {useController, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {isDefined} from 'remeda';
import {match} from 'ts-pattern';

type BaseFileInputContextData = {
  assetKind: WalletAssetKind;
  disabled?: boolean;
};

export type FileInputContextType<T extends FieldValues = FieldValues> = FormField<T> &
  BaseFileInputContextData & {
    minFileDimensions?: AssetMinDimension;
  };

export const FileInputContext = createContext<FileInputContextType | undefined>(undefined);

type FileInputProps<T extends FieldValues> = FieldWrapperProps &
  FormField<T> &
  BaseFileInputContextData & {
    children: React.ReactNode;
  };

const FileInput = <T extends FieldValues>(props: FileInputProps<T>): JSX.Element => {
  const {
    assetKind,
    control,
    children,
    className,
    disabled,
    label,
    labelPosition,
    name,
    error,
    mandatory,
    register,
  } = props;

  const minFileDimensions = Object.keys(ASSETS_MIN_DIMENSIONS).includes(assetKind)
    ? ASSETS_MIN_DIMENSIONS[assetKind as SizedAssetKind]
    : undefined;

  const contextValue: FileInputContextType<T> = {
    assetKind,
    control,
    disabled,
    minFileDimensions,
    name,
    register,
  };

  const fieldWrapperProps: FieldWrapperProps = {
    children,
    className,
    error,
    label,
    labelPosition,
    mandatory,
  };

  return (
    <FileInputContext.Provider value={contextValue as FileInputContextType<FieldValues>}>
      <FieldWrapper {...fieldWrapperProps} />
    </FileInputContext.Provider>
  );
};

/**
 * Handles the file input and preview logic, displaying a button if no file is selected.
 */
const FileInputUploader: React.FC = () => {
  const {assetKind, control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (fieldValue === '' || !isDefined(fieldValue) || isEmptyFileList(fieldValue)) {
    return <FileInputUploadButton />;
  }

  const fileType = getFileType(fieldValue);

  return match(fileType)
    .with('image', () => (
      <FileInputPreview>
        <FileInputPreviewImage>
          <FileInputDeleteButton />
        </FileInputPreviewImage>
        <FileInputPreviewFileMetadata />
        <FileInputPreviewUploadedFileMessage />
      </FileInputPreview>
    ))
    .with('video', () => (
      <FileInputPreview>
        <FileInputVideoPreview assetKind={assetKind} fileInputValue={fieldValue} />
        <FileInputPreviewFileMetadata />
      </FileInputPreview>
    ))
    .with('document', () => null)
    .with(undefined, () => null)
    .exhaustive();
};

type FileInputUploadProps = {
  assetKind: AssetKind;
  name: string;
  accept: string;
};

/**
 * Input element for uploading files.
 */
const FileInputUpload: React.FC<FileInputUploadProps> = ({name, assetKind, accept}) => {
  const {control, disabled, minFileDimensions, register, rules} = useFileInputContext();
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const {field} = useController({control, name});

  // i18nOwl-ignore [errorFileTooLarge, errorInvalidFileType, errorImageTooSmall]
  return (
    <input
      key={name}
      className={cn(
        'absolute left-0 top-0 h-full w-full opacity-0 ring-opacity-100',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      data-testid={name}
      {...register(name, rules)}
      accept={accept}
      type="file"
      onChange={async e => {
        e.stopPropagation();
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0] as File;

          if (await isValidFile(assetKind, file, minFileDimensions, t)) {
            field.onChange(file);
          }
        }
      }}
      disabled={disabled}
      value={undefined}
    />
  );
};

const UPLOAD_ICON = {
  cohortFormImage: <Image size={48} />,
  cohortFormVideo: <VideoCamera size={48} />,
  cohortFormImageOrVideo: <CameraPlus size={48} />,
};

/**
 * Displays the file upload button and handles new file selections.
 */
const FileInputUploadButton: React.FC = () => {
  const {assetKind, disabled, name} = useFileInputContext();
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const acceptedFileTypes = AllowedAssetMimeTypes[assetKind as AssetKind].options;
  const buttonIcon = UPLOAD_ICON[assetKind];

  const getUploadLabel = (): string => {
    if (assetKind === 'cohortFormImage') {
      return t('labelUploadImage');
    }
    if (assetKind === 'cohortFormVideo') {
      return t('labelUploadVideo');
    }
    return t('labelUploadImageOrVideo');
  };

  return (
    <div
      id="fileUploadInput"
      className={cn(
        'border-slate relative flex justify-center rounded-md border-2 border-dashed bg-[--xps-input-background-color] text-center text-slate-400',
        disabled && 'bg-slate-100'
      )}
    >
      <div className="flex w-full flex-col items-center">
        <div className="relative flex w-full flex-col items-center space-y-1 rounded-md p-5 ring-primary">
          <FileInputUpload
            key={name}
            assetKind={assetKind}
            name={name}
            accept={acceptedFileTypes.join(',')}
          />
          {buttonIcon}
          <p>{getUploadLabel()}</p>
        </div>
      </div>
    </div>
  );
};

type FileInputVideoPreviewProps = {
  assetKind: AssetKind;
  fileInputValue: string | File;
};

/**
 * Displays the video preview with play/pause functionality.
 */
const FileInputVideoPreview: React.FC<FileInputVideoPreviewProps> = ({
  assetKind,
  fileInputValue,
}) => {
  const videoSrc = isFile(fileInputValue)
    ? URL.createObjectURL(fileInputValue)
    : getVideoUrl(import.meta.env.COHORT_ENV, fileInputValue, {
        h: Sizes.M,
        w: Sizes.M,
      });

  return (
    <div className="flex w-full justify-around">
      <div className="relative w-[280px] flex-col gap-y-2 first-line:flex">
        <VideoPlayer aspectRatio="aspect-auto" videoSrc={videoSrc} />
        <FileInputDeleteButton />
      </div>
    </div>
  );
};

type FileInputPreviewImageProps = {
  children?: React.ReactNode;
};

/** Component to display image preview */
const FileInputPreviewImage: React.FC<FileInputPreviewImageProps> = ({children}) => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  const imageSrc = isFile(fieldValue)
    ? URL.createObjectURL(fieldValue)
    : getImageUrl(import.meta.env.COHORT_ENV, fieldValue, {h: Sizes.S, w: Sizes.S});

  return (
    <div className="relative flex w-[140px] justify-between">
      <img src={imageSrc} className="aspect-square h-full w-full rounded-md object-cover" />
      {children}
    </div>
  );
};

/** Component to display file metadata (name and size) */
const FileInputPreviewFileMetadata: React.FC = () => {
  const {control, name} = useFileInputContext();
  const fieldValue = useWatch({
    control,
    name,
  });

  if (!isFile(fieldValue)) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center gap-y-[3px]">
      <p>{fieldValue.name}</p>
    </div>
  );
};

/** Component to display uploaded status message */
const FileInputPreviewUploadedFileMessage: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'forms.fileInput'});
  const {control, name} = useFileInputContext();
  const currentFile = useWatch({
    control,
    name,
  });
  const fileType = getFileType(currentFile);

  if (isFile(currentFile)) {
    return null;
  }

  const computeText = (): string => {
    if (fileType === 'image') {
      return t('fileInputPreviewUploadedFileMessage.uploadedImage');
    }
    return t('fileInputPreviewUploadedFileMessage.uploadedVideo');
  };

  return (
    <div className="flex items-center gap-x-1.5">
      <CheckCircle size={20} className="text-green-600" />
      <div className="text-sm font-medium text-slate-700">{computeText()}</div>
    </div>
  );
};

/**
 * Displays the preview of the uploaded file.
 */
const FileInputPreview: React.FC<{children: React.ReactNode | React.ReactNode[]}> = ({
  children,
}) => {
  return (
    <div className="flex w-full justify-around rounded-lg border border-slate-200 bg-[--xps-input-background-color] p-4">
      <div className="flex flex-col items-center gap-y-2">{children}</div>
    </div>
  );
};

/**
 * Deletes the currently uploaded media file from the form field state.
 */
const FileInputDeleteButton: React.FC = () => {
  const {name, control} = useFileInputContext();
  const {field} = useController({control, name});

  return (
    <Button
      onClick={() => {
        field.onChange(null);
      }}
      variant="secondary"
      data-testid={`${name}-delete`}
      className="absolute right-2 top-2 gap-x-2 bg-black/40 p-1 text-white hover:bg-black/40 hover:text-white"
      tracking={{namespace: 'cohortForm.deleteFile'}}
    >
      <X size={16} />
    </Button>
  );
};

export {FileInput, FileInputUploader};
