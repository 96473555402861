import Title from '@cohort/components-xps/components/Title';
import {defaultErrorMessage} from '@cohort/shared/models';
import type {CohortFormAnswer, CohortFormConfig} from '@cohort/shared/schema/common/cohortForm';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import CohortFormModal from '@cohort/wallet/apps/cohort-form/modalStepper/CohortFormModal';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import {userAttributesKeys} from '@cohort/wallet/hooks/api/UserAttributes';
import useNotify from '@cohort/wallet/hooks/notify';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import {doUsePerkAccess} from '@cohort/wallet/lib/Endpoints';
import {getPerkDetailRoute} from '@cohort/wallet/lib/Pages';
import {useQueryClient} from '@tanstack/react-query';
import React, {Fragment} from 'react';
import {useTranslation} from 'react-i18next';

export const CohortFormPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onPerkUsageSuccess,
}) => {
  const {t} = useTranslation('app-cohort-form', {
    keyPrefix: 'perkIntegrations.fillForm.perkUsageComponent',
  });
  const notify = useNotify();
  const queryClient = useQueryClient();
  const navigateWithTransition = useNavigateWithTransition();

  const perkConfig = perk.integration?.config as CohortFormConfig;

  const {isLoading, mutate: doUsePerkAccessMutation} = useCohortMutation({
    mutationFn: async (values: Record<string, CohortFormAnswer>) =>
      doUsePerkAccess(perkAccess.id, null, {values}),
    onSuccess: () => {
      onPerkUsageSuccess();
      // some perk usages can update user attributes
      queryClient.invalidateQueries(userAttributesKeys.userAttributes);
    },
    onError: err => {
      if (isCohortError(err, 'perk.usages-limit-reached')) {
        notify('error', t('maxUsagesReached'), {autoClose: false});
        return;
      }
      notify('error', defaultErrorMessage);
    },
  });

  return (
    <Fragment>
      <Title testId="use-perk-form-title" className="text-center md:text-left">
        {perk.displayName}
      </Title>
      <CohortFormModal
        config={perkConfig}
        onClose={() => navigateWithTransition(getPerkDetailRoute(perk.id), undefined, 'backward')}
        onSubmitCallback={doUsePerkAccessMutation}
        isLoading={isLoading}
        trackingMetadata={{
          perkIntegrationId: perk.integration?.perkIntegrationId,
          action: 'linkAccount',
          perkAccessId: perkAccess.id,
          perkId: perk.id,
        }}
      />
    </Fragment>
  );
};
