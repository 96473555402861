import type {Language} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const SUPPORTED_TWITTER_LANGUAGES = [
  'en',
  'ar',
  'bn',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'fa',
  'fi',
  'fil',
  'fr',
  'he',
  'hi',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'msa',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'th',
  'tr',
  'uk',
  'ur',
  'vi',
  'zh-cn',
  'zh-tw',
] as const;
export const TwitterSupportedLanguageSchema = z.enum(SUPPORTED_TWITTER_LANGUAGES);
export type TwitterSupportedLanguage = z.infer<typeof TwitterSupportedLanguageSchema>;

export const mapCohortToTwitterLanguages = (
  cohortLang: Language
): (typeof SUPPORTED_TWITTER_LANGUAGES)[number] => {
  const mapping: {[key in Language]: (typeof SUPPORTED_TWITTER_LANGUAGES)[number]} = {
    ar: 'ar',
    zh: 'zh-cn', // @Devs - can be swapped with 'zh-tw' depending on preference.
    cs: 'cs',
    nl: 'nl',
    en: 'en',
    fr: 'fr',
    de: 'de',
    el: 'el',
    id: 'id',
    it: 'it',
    ja: 'ja',
    ko: 'ko',
    fa: 'fa',
    pl: 'pl',
    pt: 'pt',
    ru: 'ru',
    es: 'es',
    tr: 'tr',
    uk: 'uk',
    vi: 'vi',
  };

  return mapping[cohortLang];
};
