import MediaContentCohortImage from '@cohort/components-xps/components/contents/apps/cohort/MediaContentCohortImage';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';

const CohortImageDisplayComponent: React.FC<MediaContentProps<CohortImageMediaStruct>> = ({
  description,
  config,
  title,
}) => {
  const formattedConfig = config
    ? {
        ...config,
        imageFileKey: getImageUrl(import.meta.env.COHORT_ENV, config.imageFileKey, {
          h: Sizes.M,
          w: Sizes.M,
        }),
      }
    : config;

  return (
    <MediaContentCohortImage config={formattedConfig} title={title} description={description} />
  );
};

export default CohortImageDisplayComponent;
