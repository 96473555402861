import MediaContentTwitterPost from '@cohort/components-xps/components/contents/apps/twitter/MediaContentTwitterPost';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';

const TwitterPostDisplayComponent: React.FC<MediaContentProps<TwitterPostMediaStruct>> = ({
  config,
  description,
  title,
  isMobile,
}) => {
  const {hasDarkBg} = useThemeContext();

  return (
    <MediaContentTwitterPost
      config={config}
      title={title}
      description={description}
      hasDarkBg={hasDarkBg}
      isMobile={isMobile}
    />
  );
};

export default TwitterPostDisplayComponent;
