import type {ExternalUserProfile} from '@cohort/shared/apps/app';
import type {
  InstagramCloseFriendsListConfig,
  InstagramCloseFriendsListPerkIntegrationStruct,
} from '@cohort/shared/apps/instagram/perks/closeFriendsList';
import type {InstagramUserConnectorStruct} from '@cohort/shared/apps/instagram/userConnector';
import {InstagramUserConnectorSpec} from '@cohort/shared/apps/instagram/userConnector';
import ConnectionErrorBadge from '@cohort/shared-frontend/components/ConnectionErrorBadge';
import ConnectButton from '@cohort/wallet/apps/ConnectButton';
import InstagramApp from '@cohort/wallet/apps/instagram/InstagramApp';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import Button from '@cohort/wallet/components/button/Button';
import LoadingStep from '@cohort/wallet/components/perks/usages/LoadingStep';
import FadeInBlock from '@cohort/wallet/components/transitions/FadeInBlock';
import {useAppConnection} from '@cohort/wallet/hooks/api/Apps';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useDelayedBoolean from '@cohort/wallet/hooks/delayedBoolean';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {doUsePerkAccess} from '@cohort/wallet/lib/Endpoints';
import {ArrowSquareOut, CheckCircle, X} from '@phosphor-icons/react';
import {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

export const InstagramCloseFriendsListPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
  onPerkUsageSuccess,
}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'perkIntegrations.close-friends-list.perkUsageComponent',
  });
  const {accentColor} = useThemeContext();
  // used to generate a fake loading time
  const delayedBoolean = useDelayedBoolean(1000);

  const {data: connection, isFetched: isProfileFetched} = useAppConnection(
    InstagramUserConnectorSpec.id,
    delayedBoolean
  );

  const {
    data: perkUsage,
    mutate: consumePerk,
    isLoading: isPerkBeingUsed,
  } = useCohortMutation({
    mutationFn: async (connectionId: string) =>
      doUsePerkAccess<InstagramCloseFriendsListPerkIntegrationStruct>(
        perkAccess.id,
        connectionId,
        {}
      ),
  });

  const perkConfig = perk.integration?.config as InstagramCloseFriendsListConfig;
  const profile = connection?.profile as ExternalUserProfile<InstagramUserConnectorStruct>;

  const renderConnection = match(connection?.status)
    .with('ready', () => (
      <Fragment>
        <h3 className="flex items-center gap-2 text-lg">
          <CheckCircle className="h-6 w-6 text-green-600" />
          {t('instagramAccountLinked')}
        </h3>
        <p className="mt-4" style={{color: accentColor}}>
          <Trans
            i18nKey="perkIntegrations.close-friends-list.perkUsageComponent.connectedAs"
            ns="app-instagram"
            values={{username: profile.properties.username}}
            components={{
              bold: <span className="font-bold" />,
            }}
          />
        </p>
      </Fragment>
    ))
    .with('broken', () => <ConnectionErrorBadge label={t('connectionErrorLabel')} />)
    .otherwise(() => <Fragment />);

  return (
    <FadeInBlock appear={true} show={true}>
      <ul className="flex list-none flex-col gap-8">
        <LoadingStep isLoading={isProfileFetched} text={t('fetchingInstagramAccount')} />
        <FadeInBlock show={isProfileFetched}>
          <li>
            {!connection ? (
              <Fragment>
                <h3 className="flex items-center gap-2 text-lg">
                  <X className="h-6 w-6 text-red-600" />
                  {t('noInstagramAccount')}
                </h3>
                <ConnectButton
                  app={InstagramApp}
                  text={t('linkInstagramAccount', {username: perkConfig.username})}
                  icon={<ArrowSquareOut className="h-4 w-4 text-white" />}
                  className="mt-4 w-full"
                  tracking={{
                    namespace: 'perks.usage',
                    metadata: {
                      perkIntegrationId: perk.integration?.perkIntegrationId,
                      action: 'linkAccount',
                      perkAccessId: perkAccess.id,
                      perkId: perk.id,
                    },
                  }}
                  connectorId={InstagramUserConnectorSpec.id}
                  context={{perk}}
                />
              </Fragment>
            ) : (
              renderConnection
            )}
          </li>
        </FadeInBlock>
        <FadeInBlock
          show={
            isProfileFetched &&
            connection !== undefined &&
            connection !== null &&
            connection.status === 'broken'
          }
        >
          <ConnectButton
            app={InstagramApp}
            text={t('reconnectApp')}
            className="mt-4 w-full"
            tracking={{
              namespace: 'perks.usage',
              metadata: {
                perkIntegrationId: perk.integration?.perkIntegrationId,
                action: 'reconnectInstagramAccount',
                perkAccessId: perkAccess.id,
                perkId: perk.id,
              },
            }}
            connectorId={InstagramUserConnectorSpec.id}
            existingConnectionId={connection?.id}
            context={{perk}}
          />
        </FadeInBlock>
        <FadeInBlock
          show={
            isProfileFetched &&
            connection !== undefined &&
            connection !== null &&
            !perkUsage &&
            connection.status !== 'broken'
          }
        >
          {connection && (
            <li>
              <Button
                variant="primary"
                loading={isPerkBeingUsed}
                className="mt-4 w-full"
                onClick={() => consumePerk(connection.id)}
                tracking={{
                  namespace: 'perks.usage',
                  metadata: {
                    perkIntegrationId: perk.integration?.perkIntegrationId,
                    action: 'usePerk',
                    perkAccessId: perkAccess.id,
                    perkId: perk.id,
                  },
                }}
              >
                <ArrowSquareOut className="h-4 w-4 text-white" />
                {t('requestInvitation', {username: perkConfig.username})}
              </Button>
            </li>
          )}
        </FadeInBlock>
        <FadeInBlock
          show={isProfileFetched && perkUsage !== undefined && connection?.status !== 'broken'}
        >
          <li>
            <h3 className="flex items-center gap-2 text-xl">
              <CheckCircle className="h-8 w-8 text-green-600" />
              {t('instagramInviteRequested', {username: perkConfig.username})}
            </h3>
            <Button
              variant="primary"
              className="mt-4 w-full"
              onClick={onPerkUsageSuccess}
              tracking={{
                namespace: 'perks.usage',
                metadata: {
                  perkIntegrationId: perk.integration?.perkIntegrationId,
                  action: 'close',
                  perkAccessId: perkAccess.id,
                  perkId: perk.id,
                },
              }}
            >
              <ArrowSquareOut className="h-4 w-4 text-white" />
              {t('close')}
            </Button>
          </li>
        </FadeInBlock>
      </ul>
    </FadeInBlock>
  );
};
