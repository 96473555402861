import type {AssetKind, WalletAssetKind} from '@cohort/shared/schema/common/assets';
import {AllowedAssetMimeTypes} from '@cohort/shared/schema/common/assets';
import type {CohortFormMediaType} from '@cohort/shared/schema/common/cohortForm';
import type {
  AssetMinDimension,
  AssetMinDimensions,
  SizedAssetKind,
} from '@cohort/shared/utils/fileUploads';
import {getMaxFileSize} from '@cohort/shared/utils/fileUploads';
import notify from '@cohort/wallet/components/toasts/Toast';
import type {TFunction} from 'i18next';
import type {Control, FieldValues, Path, UseFormRegister} from 'react-hook-form';
import {match} from 'ts-pattern';

export type FormField<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  rules?: Parameters<UseFormRegister<T>>[1];
};

export const isImageTooSmall = async (
  imageSrc: string,
  minDimensions: AssetMinDimensions[SizedAssetKind]
): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = function () {
      resolve(img.width < minDimensions.width || img.height < minDimensions.height);
    };
    img.src = imageSrc;
  });
};

export const isValidFile = async (
  assetKind: AssetKind,
  file: File,
  minFileDimensions: AssetMinDimension | undefined,
  t: TFunction<'components', 'forms.fileInput'>,
  customAcceptedTypes?: string
): Promise<boolean> => {
  const acceptedTypes = customAcceptedTypes ?? AllowedAssetMimeTypes[assetKind].options.join(',');
  const fileUrl = URL.createObjectURL(file);

  // Can happen when drag & dropping a file of invalid type
  if (!acceptedTypes.includes(file.type)) {
    notify('error', t('errorInvalidFileType'));
    return false;
  }

  // Validate min size
  if (minFileDimensions && (await isImageTooSmall(fileUrl, minFileDimensions))) {
    notify(
      'error',
      t('errorImageTooSmall', {
        width: minFileDimensions.width,
        height: minFileDimensions.height,
      })
    );
    return false;
  }

  // Validate max size
  if (file.size > getMaxFileSize(assetKind, file.type).size) {
    notify('error', `${t(`errorFileTooLarge`)} ${getMaxFileSize(assetKind, file.type).label}`);
    return false;
  }

  return true;
};

export function getAcceptedAssetKindsForMediaInput(
  mediaType: CohortFormMediaType | undefined
): WalletAssetKind {
  if (mediaType === undefined) {
    throw new Error('Media type is required');
  }
  return match(mediaType)
    .with('image', () => 'cohortFormImage' as const)
    .with('video', () => 'cohortFormVideo' as const)
    .with('imageOrVideo', () => 'cohortFormImageOrVideo' as const)
    .exhaustive();
}
