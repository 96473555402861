import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

// TODO: Adapt the schema based on the real product reviewed data
export const BazaarvoiceProductReviewedPropertiesSchema = z.object({
  productId: z.string(),
  productName: z.string(),
  reviewText: z.string(),
  rating: z.number(),
});
export type BazaarvoiceProductReviewedProperties = z.infer<
  typeof BazaarvoiceProductReviewedPropertiesSchema
>;

export type BazaarvoiceProductReviewedEventStruct = UserEventStruct<
  'bazaarvoice.product.reviewed',
  keyof BazaarvoiceProductReviewedProperties,
  BazaarvoiceProductReviewedProperties,
  BazaarvoiceProductReviewedProperties
>;

export const BazaarvoiceProductReviewedEventSpec: UserEventSpec<BazaarvoiceProductReviewedEventStruct> =
  {
    id: 'bazaarvoice.product.reviewed',
    name: 'Bazaarvoice Product Reviewed',
    description: 'Event Triggerred when a user reviews a product on Bazaarvoice',
    propertiesSchema: BazaarvoiceProductReviewedPropertiesSchema,
    exposedPropertiesSchema: BazaarvoiceProductReviewedPropertiesSchema,
    resources: {},
    rulesEngineConfig: {
      isVisible: false,
      exposedFields: [],
    },
  };
