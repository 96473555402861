import {Card, CardText, CardTitleSecondary} from '@cohort/components-xps/components/cards/Card';
import type {CreateConnectionComponentProps} from '@cohort/wallet/apps';
import {getMerchantConnectionIdAndUsernameFromContext} from '@cohort/wallet/apps/instagram/connector/utils';
import Button from '@cohort/wallet/components/button/Button';
import {Input} from '@cohort/wallet/components/forms/Input';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

const InstagramCreateConnectionComponent: React.FC<CreateConnectionComponentProps> = ({
  context,
  isSubmitting,
  onSubmit,
  onCancel,
}) => {
  const {t} = useTranslation('app-instagram', {
    keyPrefix: 'connector',
  });
  const [username, setUsername] = useState('');

  const {merchantConnectionId, merchantUsername} =
    getMerchantConnectionIdAndUsernameFromContext(context);

  if (merchantConnectionId === undefined || merchantUsername === undefined) {
    return (
      <div className="flex flex-col gap-6">
        <p className="text-sm">{t('unavailable')}</p>

        <Button
          onClick={onCancel}
          variant="secondary"
          tracking={{namespace: 'apps.instagram.closeConnectUnavailable'}}
        >
          {t('buttonClose')}
        </Button>
      </div>
    );
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit({
      username,
      verifiedWithMerchantConnectionId: merchantConnectionId,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6">
      <p className="text-sm">{t('description')}</p>
      <Input
        type="text"
        label={t('usernameLabel')}
        placeholder={t('usernamePlaceholder')}
        value={username}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
        required
      />
      <Card className="flex flex-col gap-2 p-6">
        <CardTitleSecondary>
          <span className="pr-2">💬</span>
          {t('sendDMTitle')}
        </CardTitleSecondary>
        <CardText>
          {t('sendDMDescription', {
            username: merchantUsername,
          })}
        </CardText>
        <Button
          className="mt-4"
          variant="secondary"
          size="small"
          tracking={{namespace: 'apps.instagram.createConnection'}}
          onClick={() => {
            window.open(
              `https://www.instagram.com/${merchantUsername}`,
              '_blank',
              'noopener,noreferrer'
            );
          }}
        >
          {t('buttonGoToInstagram')}
        </Button>
      </Card>
      <Button
        type="submit"
        variant="primary"
        tracking={{namespace: 'apps.instagram.createConnection'}}
        loading={isSubmitting}
      >
        {t('buttonConnect')}
      </Button>
    </form>
  );
};

export default InstagramCreateConnectionComponent;
