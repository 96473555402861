import type {TikTokPostMediaStruct} from '@cohort/shared/apps/tiktok/medias/post';
import {TikTokPostMediaSpec} from '@cohort/shared/apps/tiktok/medias/post';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import TikTokPostDisplayComponent from '@cohort/wallet/apps/tiktok/medias/post/DisplayComponent';
import TikTokPostVisualPreview from '@cohort/wallet/apps/tiktok/medias/post/VisualPreview';

export const TikTokPostContentMedia: ContentMedia<TikTokPostMediaStruct> = {
  spec: TikTokPostMediaSpec,
  getTitle: t => t('medias.post.title', {ns: 'app-tiktok'}),
  displayComponent: TikTokPostDisplayComponent,
  icon: ({size}) => <SvgAppIcon name="tiktok" height={size} width={size} />,
  visualPreview: TikTokPostVisualPreview,
};
