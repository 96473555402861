import type {CohortImageMediaStruct} from '@cohort/shared/apps/cohort/medias/image';
import {getImageUrl, Sizes} from '@cohort/shared/utils/media';
import type {VisualPreviewProps} from '@cohort/wallet/apps/Media';
import {contentVisualPreviewImageElement} from '@cohort/wallet/apps/utils/contents';

const CohortImageVisualPreview: React.FC<VisualPreviewProps<CohortImageMediaStruct>> = ({
  config,
  title,
  imageSize = 'XL',
}) => {
  const imageFileKey = config.imageFileKey;
  const imageSrc = getImageUrl(import.meta.env.COHORT_ENV, imageFileKey, {
    h: Sizes[imageSize],
    w: Sizes[imageSize],
  });
  return contentVisualPreviewImageElement(title, imageSrc);
};

export default CohortImageVisualPreview;
