import {DatetimeSchema} from '@cohort/shared/schema/common';
import {VerificationAttemptInputSchema} from '@cohort/wallet-schemas/challengeStepTriggers';
import {z} from 'zod';

export const ChallengeParticipationWSchema = z.object({
  id: z.string().uuid(),
  campaignId: z.string().uuid(),
  canBeCompleted: z.boolean(),
  completedAt: DatetimeSchema.nullable(),
  completedChallengeStepIds: z.array(z.string().uuid()),
  isCompleted: z.boolean(),
  rewardBoxId: z.string().nullable(),
  stepsCompleted: z.boolean(),
});
export type ChallengeParticipationWDto = z.infer<typeof ChallengeParticipationWSchema>;

export const JoinCampaignChallengeWSchema = z.object({
  campaignId: z.string().uuid(),
});

export const VerifyChallengeStepWSchema = z.object({
  challengeStepId: z.string().uuid(),
  challengeStepTriggerId: z.string().uuid(),
  userConnectionId: z.string().uuid().nullable(),
  verificationAttemptInput: VerificationAttemptInputSchema,
  skipAttemptGeneration: z.boolean().default(false).optional(),
});
export type VerifyChallengeStepWDto = z.infer<typeof VerifyChallengeStepWSchema>;
