import FieldWrapper from '@cohort/wallet/components/forms/FieldWrapper';
import {useTranslation} from 'react-i18next';
import type {GroupBase, Props, StylesConfig} from 'react-select';
import Select from 'react-select';

export type SelectOption = {
  value: string | boolean | number | Record<string, unknown>;
  label: string | JSX.Element;
  isDisabled?: boolean;
};

export type AdditionalSelectPickerProps = {
  label?: string;
  mandatory?: boolean;
  error?: string | JSX.Element;
};

export const SelectPicker = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  label,
  mandatory,
  error,
  menuPlacement = 'auto',
  menuPortalTarget,
  ...props
}: Props<Option, IsMulti, Group> & AdditionalSelectPickerProps): JSX.Element => {
  const {t} = useTranslation('components', {keyPrefix: 'forms.selectPicker'});

  return (
    <FieldWrapper label={label} mandatory={mandatory} error={error}>
      <Select
        id="react-select-picker"
        menuPortalTarget={menuPortalTarget !== undefined ? menuPortalTarget : document.body}
        menuPlacement={menuPlacement}
        styles={
          {
            ...stylesConfig,
          } as StylesConfig<Option, IsMulti, Group>
        }
        placeholder={t('placeholder')}
        {...props}
      />
    </FieldWrapper>
  );
};

const stylesConfig: StylesConfig = {
  container: baseStyles => ({
    ...baseStyles,
  }),
  control: baseStyles => {
    return {
      ...baseStyles,
      color: 'var(--xps-input-text-color)',
      borderRadius: 'var(--xps-input-border-radius)',
      borderColor: 'var(--xps-input-border-color)',
      backgroundColor: 'var(--xps-input-background-color)',
      fontSize: '0.875rem',
      cursor: 'pointer',
      '&:focus-within': {
        boxShadow: '0 0 0 2px var(--xps-accent-color)',
        border: 'transparent',
      },
      '&:hover': {},
    };
  },
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: 'var(--xps-accent-color)',
  }),
  multiValue: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'var(--xps-background-color)',
    border: `1px solid var(--xps-input-border-color)`,
    borderRadius: '4px',
  }),
  multiValueLabel: baseStyles => ({
    ...baseStyles,
    color: 'hsl(var(--foreground))',
    fontSize: '0.75rem',
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: 'var(--xps-input-text-color)',
  }),
  menuList: baseStyles => ({
    ...baseStyles,
    padding: '4px',
    border: 'none',
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'var(--xps-background-color)',
    borderRadius: 'var(--xps-input-border-radius)',
    border: `1px solid var(--xps-input-border-color)`,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor:
      state.isSelected && state.isFocused ? 'var(--xps-accent-color)' : 'transparent',
    cursor: 'pointer',
    color: state.isSelected ? 'var(--xps-primary-btn-color)' : 'hsl(var(--foreground))',
    borderRadius: 'var(--xps-input-border-radius)',
    fontSize: '1rem',
  }),
  multiValueRemove: baseStyles => ({
    ...baseStyles,
    '&:hover': {
      color: 'var(--xps-accent-color)',
      backgroundColor: 'var(--xps-background-color)',
    },
  }),
  input: baseStyles => ({
    ...baseStyles,
    boxShadow: 'none',
  }),
  clearIndicator: baseStyles => ({
    ...baseStyles,
    '&:hover': {
      color: 'var(--xps-accent-color)',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuPortal: base => ({...base, zIndex: 9999}),
};
