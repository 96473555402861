import SpotifyPlayer from '@cohort/components-xps/components/apps/spotify/SpotifyPlayer';
import type {MediaContentProps} from '@cohort/components-xps/components/contents/apps/types';
import MediaContentInfos from '@cohort/components-xps/components/contents/MediaContentInfos';
import type {SpotifyPlaylistMediaStruct} from '@cohort/shared/apps/spotify/medias/playlist';
import {isString} from 'remeda';

const MediaContentSpotifyPlaylist: React.FC<MediaContentProps<SpotifyPlaylistMediaStruct>> = ({
  config,
  description,
  isMobile,
  title,
}) => (
  <div className="space-y-4">
    {isString(config?.playlistId) && (
      <SpotifyPlayer
        resourceId={config.playlistId}
        resourceType="playlist"
        onError={() => {}}
        size={isMobile ? 'sm' : 'lg'}
      />
    )}
    <MediaContentInfos title={title} description={description} />
  </div>
);

export default MediaContentSpotifyPlaylist;
