/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {ConnectorId} from '@cohort/shared/apps';
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {getConnectionByConnectorId, getConnections} from '@cohort/wallet/lib/Endpoints';

export const appsKeys = {
  apps: ['apps'] as const,
  listConnections: () => [...appsKeys.apps, 'listConnections'] as const,
  appConnection: (connectorId: ConnectorId) => [...appsKeys.apps, connectorId] as const,
};

export const useAppConnections = (enabled?: boolean) => {
  return useCohortQuery({
    queryKey: appsKeys.listConnections(),
    queryFn: async () => getConnections(),
    enabled,
  });
};

export const useAppConnection = (connectorId: ConnectorId, enabled?: boolean) => {
  return useCohortQuery({
    queryKey: appsKeys.appConnection(connectorId),
    queryFn: async () => getConnectionByConnectorId(connectorId),
    select: data => data.connection,
    enabled,
  });
};
