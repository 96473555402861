import {isDocumentFile, isImageFile, isVideoFile} from '@cohort/shared/utils/mimeTypes';
import {isFile} from '@cohort/shared-frontend/utils/isFile';
import {getType} from 'mime/lite';

export type UploadedFileTypes = 'image' | 'video' | 'document';

export function getFileType(file: File | string): UploadedFileTypes | undefined {
  const type = isFile(file) ? file.type : getType(file);

  if (type && isVideoFile(type)) {
    return 'video';
  }
  if (type && isImageFile(type)) {
    return 'image';
  }
  if (type && isDocumentFile(type)) {
    return 'document';
  }
  return undefined;
}

export function getFileTypeFromMimeType(
  mimeTypes: string[] | undefined
): UploadedFileTypes | undefined {
  if (mimeTypes && mimeTypes.some(type => isDocumentFile(type))) {
    return 'document';
  }
  if (mimeTypes && mimeTypes.some(type => isImageFile(type))) {
    return 'image';
  }
  if (mimeTypes && mimeTypes.some(type => isVideoFile(type))) {
    return 'video';
  }
  return undefined;
}
