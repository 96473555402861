import type {ConnectorId} from '@cohort/shared/apps';
import type {App} from '@cohort/wallet/apps';
import CustomConnectButton from '@cohort/wallet/apps/CustomConnectButton';
import OAuthConnectButton from '@cohort/wallet/apps/OAuthConnectButton';
import type {ButtonBaseProps} from '@cohort/wallet/components/button/Button';
import type {ChallengeWDto} from '@cohort/wallet-schemas/campaign';
import type {ChallengeStepTriggerWDto} from '@cohort/wallet-schemas/challengeSteps';
import type {PerkWDto} from '@cohort/wallet-schemas/perk';

export type ConnectButtonContext = {
  challenge?: ChallengeWDto;
  trigger?: ChallengeStepTriggerWDto;
  perk?: PerkWDto;
};

export type ConnectButtonProps = Omit<ButtonBaseProps, 'loading' | 'onClick'> & {
  app: App;
  connectorId: ConnectorId;
  existingConnectionId?: string;
  icon?: JSX.Element;
  userDataPolicyPosition?: 'left' | 'right';
  context?: ConnectButtonContext;
};

const ConnectButton: React.FC<ConnectButtonProps> = props => {
  if (props.app.spec.userConnector.type === 'custom') {
    return <CustomConnectButton {...props} />;
  }

  return <OAuthConnectButton {...props} />;
};

export default ConnectButton;
