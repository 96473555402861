import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortContentAvailablePropertiesSchema = z.object({
  contentId: z.string().uuid(),
  contentInternalName: z.string(),
  contentMediaKind: z.string(),
});
export type CohortContentAvailableProperties = z.infer<
  typeof CohortContentAvailablePropertiesSchema
>;

export type CohortContentAvailableEventStruct = UserEventStruct<
  'cohort.content.available',
  keyof CohortContentAvailableProperties,
  CohortContentAvailableProperties,
  CohortContentAvailableProperties
>;

export const CohortContentAvailableEventSpec: UserEventSpec<CohortContentAvailableEventStruct> = {
  id: 'cohort.content.available',
  name: 'Content Available',
  description: 'Event Triggerred when a Content is available to the user',
  propertiesSchema: CohortContentAvailablePropertiesSchema,
  exposedPropertiesSchema: CohortContentAvailablePropertiesSchema,
  resources: {
    contentId: 'cohort.content',
  },
  rulesEngineConfig: {
    isVisible: true,
    exposedFields: ['contentId'],
  },
};
