import type {LocalizedString} from '@cohort/shared/schema/common';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useCallback} from 'react';

export default function useGetLocalizedString(): (localizedString: LocalizedString) => string {
  const merchant = useMerchantContext();
  const user = useUserStore(store => store.user);

  const getLocalizedString = useCallback(
    (localizedString: LocalizedString): string => {
      return (
        localizedString[user?.lang ?? merchant.defaultLanguage] ??
        localizedString[merchant.defaultLanguage] ??
        ''
      );
    },
    [merchant, user]
  );

  return getLocalizedString;
}
