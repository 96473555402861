/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useCohortQuery} from '@cohort/wallet/hooks/api/Query';
import {getChallengeBySlug, getStoreBySlug} from '@cohort/wallet/lib/Endpoints';

export const campaignsKeys = {
  campaigns: ['campaigns'] as const,
  getStoreBySlug: (merchantId: string, campaignSlug: string, redeemCode?: string) =>
    [...campaignsKeys.campaigns, merchantId, campaignSlug, redeemCode] as const,
  getChallengeBySlug: (merchantId: string, challengeSlug: string) =>
    [...campaignsKeys.campaigns, merchantId, 'challenge', challengeSlug] as const,
};

export const useStoreBySlug = (
  merchantId: string,
  campaignSlug: string,
  redeemCode?: string,
  enabled?: boolean
) => {
  return useCohortQuery({
    queryKey: campaignsKeys.getStoreBySlug(merchantId, campaignSlug, redeemCode),
    queryFn: async () => getStoreBySlug(merchantId, campaignSlug, redeemCode),
    enabled,
  });
};

export const useChallengeBySlug = (
  merchantId: string,
  challengeSlug: string,
  enabled?: boolean
) => {
  return useCohortQuery({
    queryKey: campaignsKeys.getChallengeBySlug(merchantId, challengeSlug),
    queryFn: async () => getChallengeBySlug(merchantId, challengeSlug),
    enabled,
  });
};
