import type {ConnectorSpec} from '@cohort/shared/apps/app';
import type {BaseProfileProperties, ConnectorStruct} from '@cohort/shared/apps/app';
import {BaseProfilePropertiesSchema} from '@cohort/shared/apps/app';
import {urlTransformer, urlValidator} from '@cohort/shared/utils/validators';
import {z} from 'zod';

export const ComarchCredentialsSchema = z.object({
  apiUrl: z
    .string()
    .refine(url => urlValidator(url), 'errorUrl')
    .transform(url => urlTransformer(url)),
  programId: z.number().nullable(),
  programCode: z.string().nullable(),
  partnerCode: z.string().nullable(),
  transactionType: z.string().length(2),
  username: z.string(),
  password: z.string(),
  accessToken: z.string().optional(),
});
export type ComarchCredentials = z.infer<typeof ComarchCredentialsSchema>;

export type ComarchConnectorStruct = ConnectorStruct<
  'comarch',
  null,
  ComarchCredentials,
  BaseProfileProperties,
  null
>;

export const ComarchConnectorSpec = {
  id: 'comarch',
  type: 'custom',
  appCredentialsSchema: null,
  credentialsSchema: ComarchCredentialsSchema,
  profilePropertiesSchema: BaseProfilePropertiesSchema,
  connectionConfigSchema: null,
} satisfies ConnectorSpec<ComarchConnectorStruct>;
