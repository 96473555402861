import type {UserEventSpec, UserEventStruct} from '@cohort/shared/apps/userEvent';
import {z} from 'zod';

export const CohortRewardBoxOpenedPropertiesSchema = z.object({
  rewardBoxId: z.string().uuid(),
  campaignId: z.string().uuid().nullable(),
  campaignInternalName: z.string().nullable(),
  rewardConfigurationId: z.string().uuid(),
});
export type CohortRewardBoxOpenedProperties = z.infer<typeof CohortRewardBoxOpenedPropertiesSchema>;

export type CohortRewardBoxOpenedEventStruct = UserEventStruct<
  'cohort.reward-box.opened',
  keyof CohortRewardBoxOpenedProperties,
  CohortRewardBoxOpenedProperties,
  CohortRewardBoxOpenedProperties
>;

export const CohortRewardBoxOpenedEventSpec: UserEventSpec<CohortRewardBoxOpenedEventStruct> = {
  id: 'cohort.reward-box.opened',
  name: 'Opened a Mystery Box',
  description: 'Event Triggered when a user opens a Mystery Box',
  propertiesSchema: CohortRewardBoxOpenedPropertiesSchema,
  exposedPropertiesSchema: CohortRewardBoxOpenedPropertiesSchema,
  resources: {},
  rulesEngineConfig: {
    isVisible: false,
    exposedFields: [],
  },
};
