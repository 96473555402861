import type {TwitterPostMediaStruct} from '@cohort/shared/apps/twitter/medias/post';
import {TwitterPostMediaSpec} from '@cohort/shared/apps/twitter/medias/post';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import TwitterPostDisplayComponent from '@cohort/wallet/apps/twitter/medias/post/DisplayComponent';
import TwitterPostVisualPreview from '@cohort/wallet/apps/twitter/medias/post/VisualPreview';

export const TwitterPostContentMedia: ContentMedia<TwitterPostMediaStruct> = {
  spec: TwitterPostMediaSpec,
  getTitle: t => t('medias.post.title', {ns: 'app-twitter'}),
  displayComponent: TwitterPostDisplayComponent,
  icon: ({size}) => <SvgAppIcon name="twitter" height={size} width={size} />,
  visualPreview: TwitterPostVisualPreview,
};
