import type {SetAmplitudeMetadataContext} from '@cohort/wallet/lib/Tracking';
import {setAmplitudeMetadata} from '@cohort/wallet/lib/Tracking';
import {useState} from 'react';
import {useEffect} from 'react';

type TrackerConfig = SetAmplitudeMetadataContext;
export default function useSetTrackingConfig(config: TrackerConfig): boolean {
  const [trackingReady, setTrackingReady] = useState(false);

  useEffect(() => {
    if (!trackingReady) {
      setAmplitudeMetadata(config);
      setTrackingReady(true);
    }
  }, [config, trackingReady]);

  return trackingReady;
}
