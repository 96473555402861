import type {DiscordPrivateAccessUsageData} from '@cohort/shared/apps/discord/perks/privateAccess';
import ConnectionErrorBadge from '@cohort/shared-frontend/components/ConnectionErrorBadge';
import ConnectButton from '@cohort/wallet/apps/ConnectButton';
import DiscordApp from '@cohort/wallet/apps/discord/DiscordApp';
import type {PerkUsageComponentProps} from '@cohort/wallet/apps/PerkIntegration';
import Button from '@cohort/wallet/components/button/Button';
import LoadingStep from '@cohort/wallet/components/perks/usages/LoadingStep';
import FadeInBlock from '@cohort/wallet/components/transitions/FadeInBlock';
import {useAppConnection} from '@cohort/wallet/hooks/api/Apps';
import {useConsumePerkAccess} from '@cohort/wallet/hooks/api/PerkAccesses';
import useDelayedBoolean from '@cohort/wallet/hooks/delayedBoolean';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {ArrowSquareOut, CheckCircle, X} from '@phosphor-icons/react';
import React, {Fragment} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

export const DiscordPrivateAccessPerkUsageComponent: React.FC<PerkUsageComponentProps> = ({
  perkAccess,
  perk,
}) => {
  const {t} = useTranslation('app-discord', {
    keyPrefix: 'perkIntegrations.private-access.perkUsageComponent',
  });
  const {accentColor} = useThemeContext();
  // used to generate a fake loading time
  const delayedBoolean = useDelayedBoolean(1000);

  const {data: connection, isFetched: isProfileFetched} = useAppConnection(
    'discord',
    delayedBoolean
  );

  const {data: perkUsage, isFetched: isInviteFetched} = useConsumePerkAccess(
    perkAccess.id,
    connection?.id || '',
    {
      discordUserId: connection?.profile?.id,
      discordUserName: connection?.profile?.properties.username,
      discordDiscriminator: connection?.profile?.properties.discriminator,
    },
    connection !== undefined && connection !== null && connection.status === 'ready'
  );
  const usageData = perkUsage?.integration?.data as DiscordPrivateAccessUsageData | undefined;

  const renderConnection = match(connection?.status)
    .with('ready', () => (
      <Fragment>
        <h3 className="flex items-center gap-2 text-lg">
          <CheckCircle className="h-6 w-6 text-green-600" />
          {t('discordAccountLinked')}
        </h3>
        <p className="mt-4" style={{color: accentColor}}>
          <Trans
            i18nKey="perkIntegrations.private-access.perkUsageComponent.connectedAs"
            ns="app-discord"
            values={{username: connection?.profile?.properties.username}}
            components={{
              bold: <span className="font-bold" />,
            }}
          />
        </p>
      </Fragment>
    ))
    .with('broken', () => <ConnectionErrorBadge label={t('connectionErrorLabel')} />)
    .otherwise(() => <Fragment />);

  return (
    <FadeInBlock appear={true} show={true}>
      <ul className="flex list-none flex-col gap-8">
        <LoadingStep isLoading={isProfileFetched} text={t('fetchingDiscordAccount')} />
        <FadeInBlock show={isProfileFetched}>
          <li>
            {!connection ? (
              <Fragment>
                <h3 className="flex items-center gap-2 text-lg">
                  <X className="h-6 w-6 text-red-600" />
                  {t('noDiscordAccount')}
                </h3>
                <ConnectButton
                  app={DiscordApp}
                  text={t('linkDiscordAccount')}
                  icon={<ArrowSquareOut className="h-4 w-4 text-white" />}
                  className="mt-4 w-full"
                  tracking={{
                    namespace: 'perks.usage',
                    metadata: {
                      perkIntegrationId: perk.integration?.perkIntegrationId,
                      action: 'linkAccount',
                      perkAccessId: perkAccess.id,
                      perkId: perk.id,
                    },
                  }}
                  connectorId="discord"
                />
              </Fragment>
            ) : (
              renderConnection
            )}
          </li>
        </FadeInBlock>
        <FadeInBlock
          show={isProfileFetched && connection !== undefined && connection?.status === 'broken'}
        >
          <ConnectButton
            app={DiscordApp}
            text={t('reconnectApp')}
            className="mt-4 w-full"
            tracking={{
              namespace: 'perks.usage',
              metadata: {
                perkIntegrationId: perk.integration?.perkIntegrationId,
                action: 'reconnectDiscordAccount',
                perkAccessId: perkAccess.id,
                perkId: perk.id,
              },
            }}
            connectorId="discord"
            existingConnectionId={connection?.id}
          />
        </FadeInBlock>
        <FadeInBlock
          show={
            isProfileFetched &&
            connection !== undefined &&
            connection !== null &&
            connection.status === 'ready'
          }
        >
          <LoadingStep isLoading={isInviteFetched} text={t('discordInvitateCreating')} />
        </FadeInBlock>
        <FadeInBlock show={isProfileFetched && isInviteFetched && connection?.status === 'ready'}>
          <li>
            <h3 className="flex items-center gap-2 text-lg">
              <CheckCircle className="h-6 w-6 text-green-600" />
              {t('discordInviteReady')}
            </h3>
            <p style={{color: accentColor}} className="mt-4 text-right">
              <Trans
                i18nKey="perkIntegrations.private-access.perkUsageComponent.discordJoinServerHint"
                ns="app-discord"
                values={{serverName: usageData?.guildName}}
                components={{
                  bold: <span className="font-bold" />,
                }}
              />
            </p>
            <Button
              variant="primary"
              loading={!isInviteFetched}
              className="mt-4 w-full"
              onClick={() => usageData && window.location.replace(usageData.url)}
              tracking={{
                namespace: 'perks.usage',
                metadata: {
                  appIperkIntegrationIdd: perk.integration?.perkIntegrationId,
                  action: 'joinServer',
                  perkAccessId: perkAccess.id,
                  perkId: perk.id,
                },
              }}
            >
              <ArrowSquareOut className="h-4 w-4 text-white" />
              {t('discordJoinServer')}
            </Button>
          </li>
        </FadeInBlock>
      </ul>
    </FadeInBlock>
  );
};
