'use client';

import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {UseEmblaCarouselType} from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';
import {createContext, forwardRef, useCallback, useContext, useEffect, useState} from 'react';
import {match} from 'ts-pattern';

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: 'horizontal' | 'vertical';
  setApi?: (api: CarouselApi) => void;
  isDraggable?: boolean;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  selectedIndex: number;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

const useCarousel = (): CarouselContextProps => {
  const context = useContext(CarouselContext);

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />');
  }

  const {api} = context;
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!api) return;

    const updateSelectedIndex = (): void => setSelectedIndex(api.selectedScrollSnap());

    updateSelectedIndex(); // Initialize the selected index
    api.on('select', updateSelectedIndex);

    return () => {
      api.off('select', updateSelectedIndex);
    };
  }, [api]);

  return {
    ...context,
    selectedIndex,
  };
};

const Carousel = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      isDraggable = true,
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
        watchDrag: isDraggable,
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setSelectedIndex(api.selectedScrollSnap());
    }, []);

    const scrollPrev = useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on('reInit', onSelect);
      api.on('select', onSelect);

      return () => {
        api.off('select', onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: opts?.axis === 'y' ? 'vertical' : 'horizontal',
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn('relative', className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = 'Carousel';

const CarouselContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, ...props}, ref) => {
    const {carouselRef, orientation} = useCarousel();

    return (
      <div ref={carouselRef} className="flex grow flex-col overflow-hidden">
        <div
          ref={ref}
          className={cn(
            'flex',
            orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
            className
          )}
          {...props}
        />
      </div>
    );
  }
);
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {badgeContent?: React.ReactNode}
>(({className, badgeContent, ...props}, ref) => {
  const {orientation} = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        'min-w-0 shrink-0 grow-0 basis-full',
        orientation === 'horizontal' ? 'pl-4' : 'pt-4',
        className
      )}
      {...props}
    />
  );
});
CarouselItem.displayName = 'CarouselItem';

type CarouselProgressProps = {
  color: string;
  disableClick?: boolean;
  variant?: 'dots' | 'progressBar';
  label?: string;
};

const CarouselProgress: React.FC<CarouselProgressProps> = ({
  color,
  disableClick = false,
  variant = 'dots',
  label,
}) => {
  const {api, canScrollNext, canScrollPrev} = useCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [slideCount, setSlideCount] = useState(0);

  useEffect(() => {
    if (!api) return;

    const updateSelectedIndex = (): void => {
      setSelectedIndex(api.selectedScrollSnap());
    };

    const updateSlideCount = (): void => {
      setSlideCount(api.scrollSnapList().length);
    };

    api.on('select', updateSelectedIndex);
    api.on('reInit', updateSlideCount);

    updateSelectedIndex();
    updateSlideCount();

    return () => {
      api.off('select', updateSelectedIndex);
      api.off('reInit', updateSlideCount);
    };
  }, [api]);

  const handleDotClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    api?.scrollTo(index);
  };

  const computeClassNames = (index: number): string | React.CSSProperties => {
    return match(variant)
      .with('dots', () =>
        cn('h-2 w-2', index === selectedIndex ? {backgroundColor: color} : 'bg-gray-400')
      )
      .with('progressBar', () =>
        cn('h-1.5 grow', index < selectedIndex ? {backgroundColor: color} : 'bg-gray-400')
      )
      .exhaustive();
  };

  const computeStyle = (index: number): {backgroundColor: string} => {
    const backgroundColor = match(variant)
      .with('dots', () => (index === selectedIndex ? color : 'gray'))
      .with('progressBar', () => (index <= selectedIndex ? color : 'gray'))
      .exhaustive();
    return {backgroundColor};
  };

  return (
    <div
      className={cn(
        label && 'flex h-full flex-col justify-between',
        disableClick ? 'pointer-events-none cursor-default' : 'cursor-pointer'
      )}
    >
      {label && <div className="text-center text-xs font-semibold">{label}</div>}
      <div className={cn('flex justify-center space-x-2', variant === 'dots' ? 'gap-2' : 'w-full')}>
        {Array.from({length: slideCount}).map((_, index) => (
          <button
            key={index}
            type="button"
            className={cn('rounded-full', computeClassNames(index))}
            style={computeStyle(index)}
            onClick={handleDotClick(index)}
            disabled={!canScrollNext && !canScrollPrev}
          />
        ))}
      </div>
    </div>
  );
};
export {type CarouselApi, Carousel, CarouselContent, CarouselItem, CarouselProgress, useCarousel};
