import Title from '@cohort/components-xps/components/Title';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import {isCohortError} from '@cohort/shared/schema/common/errors';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {App} from '@cohort/wallet/apps';
import type {ConnectButtonContext, ConnectButtonProps} from '@cohort/wallet/apps/ConnectButton';
import DataPolicyModal from '@cohort/wallet/apps/DataPolicyModal';
import Button from '@cohort/wallet/components/button/Button';
import {ModalOrDrawer} from '@cohort/wallet/components/modals/Modal';
import {appsKeys} from '@cohort/wallet/hooks/api/Apps';
import {campaignsKeys} from '@cohort/wallet/hooks/api/Campaign';
import {useCohortMutation} from '@cohort/wallet/hooks/api/Query';
import useNotify from '@cohort/wallet/hooks/notify';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {createCustomConnection} from '@cohort/wallet/lib/Endpoints';
import {Info} from '@phosphor-icons/react';
import {useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

type ConnectionFormModalProps = {
  app: App;
  context?: ConnectButtonContext;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (credentials: Record<string, string>) => void;
};

const ConnectionFormModal: React.FC<ConnectionFormModalProps> = ({
  app,
  context,
  isSubmitting,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation('apps', {keyPrefix: 'customConnectButton.connectionFormModal'});

  const title = <Title>{t('title', {appName: app.spec.name})}</Title>;

  return (
    <ModalOrDrawer
      tracking={{namespace: 'modals.apps.connectionForm'}}
      onClose={onClose}
      modalProps={{title, width: 500}}
      drawerProps={{title}}
    >
      {app.CreateConnectionComponent && (
        <app.CreateConnectionComponent
          onSubmit={onSubmit}
          onCancel={onClose}
          context={context}
          isSubmitting={isSubmitting}
        />
      )}
    </ModalOrDrawer>
  );
};

const CustomConnectButton: React.FC<ConnectButtonProps> = ({
  app,
  connectorId,
  existingConnectionId,
  userDataPolicyPosition = 'left',
  context,
  ...props
}) => {
  const [dataPolicyModal, setDataPolicyModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const merchant = useMerchantContext();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const {mutate: startCustomConnection, isLoading} = useCohortMutation({
    mutationFn: async (credentials: Record<string, string>) =>
      createCustomConnection({
        connectorId,
        existingConnectionId,
        credentials,
      }),
    onSuccess: async () => {
      setFormModal(false);
      if (context?.challenge !== undefined) {
        await queryClient.invalidateQueries(
          campaignsKeys.getChallengeBySlug(merchant.id, context.challenge.slug)
        );
      }
      await queryClient.invalidateQueries(appsKeys.listConnections());
      await queryClient.invalidateQueries(appsKeys.appConnection(connectorId));
    },
    onError: error => {
      if (app.customConnectionErrorMessage && isCohortError(error) && 'cause' in error.context) {
        const errorMessage =
          app.customConnectionErrorMessage(error.context.cause as CohortErrorCode) ?? null;
        if (errorMessage !== null) {
          notify('error', errorMessage.title);
          return;
        }
      }
    },
  });

  return (
    <div>
      {dataPolicyModal && <DataPolicyModal app={app} onClose={() => setDataPolicyModal(false)} />}
      {formModal && (
        <ConnectionFormModal
          app={app}
          onClose={() => setFormModal(false)}
          onSubmit={startCustomConnection}
          context={context}
          isSubmitting={isLoading}
        />
      )}
      <div className="flex items-center gap-2">
        {app.withUserDataPolicy && (
          <Button
            variant="secondary"
            className={cn(
              props.className,
              '!w-auto !rounded-xl !px-2 !py-2',
              userDataPolicyPosition === 'left' ? 'order-first' : 'order-last'
            )}
            onClick={() => setDataPolicyModal(true)}
            tracking={{
              namespace: 'apps.userDataPolicy',
              metadata: {appId: app.spec.id},
            }}
          >
            {props.icon}
            <Info size={20} />
          </Button>
        )}
        <Button
          variant="primary"
          {...props}
          className={cn(
            props.className,
            userDataPolicyPosition === 'left' ? 'order-last' : 'order-first'
          )}
          loading={isLoading}
          onClick={() => setFormModal(true)}
        >
          {props.icon}
          {props.text}
        </Button>
      </div>
    </div>
  );
};

export default CustomConnectButton;
