import type {AppSpec, AppStruct} from '@cohort/shared/apps/app';
import type {CohortErrorCode} from '@cohort/shared/schema/common/errors';
import CohortApp from '@cohort/wallet/apps/cohort/CohortApp';
import CohortFormApp from '@cohort/wallet/apps/cohort-form/CohortFormApp';
import type {ConnectButtonContext} from '@cohort/wallet/apps/ConnectButton';
import DiscordApp from '@cohort/wallet/apps/discord/DiscordApp';
import FacebookApp from '@cohort/wallet/apps/facebook/FacebookApp';
import GarminConnectApp from '@cohort/wallet/apps/garmin-connect/GarminConnectApp';
import GoogleFitApp from '@cohort/wallet/apps/google-fit/GoogleFitApp';
import InstagramApp from '@cohort/wallet/apps/instagram/InstagramApp';
import type {ContentMedia} from '@cohort/wallet/apps/Media';
import type {PerkIntegration} from '@cohort/wallet/apps/PerkIntegration';
import ShopifyApp from '@cohort/wallet/apps/shopify/ShopifyApp';
import SpotifyApp from '@cohort/wallet/apps/spotify/SpotifyApp';
import StravaApp from '@cohort/wallet/apps/strava/StravaApp';
import TalonOneApp from '@cohort/wallet/apps/talon-one/TaloneOneApp';
import TikTokApp from '@cohort/wallet/apps/tiktok/TikTokApp';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';
import TwitterApp from '@cohort/wallet/apps/twitter/TwitterApp';
import TypeformApp from '@cohort/wallet/apps/typeform/TypeformApp';
import YoutubeApp from '@cohort/wallet/apps/youtube/YoutubeApp';
import type React from 'react';
import {compact} from 'remeda';

export type ConnectionErrorMessage = {
  title: string;
  description?: string;
};
export type CustomConnectionErrorMessage = (
  cause: CohortErrorCode
) => ConnectionErrorMessage | undefined;

export type CreateConnectionComponentProps = {
  context?: ConnectButtonContext;
  isSubmitting?: boolean;
  onSubmit: (credentials: Record<string, string>) => void;
  onCancel: () => void;
};

export type App<T extends AppStruct = AppStruct> = {
  spec: AppSpec<T>;
  perkIntegrations: ReadonlyArray<PerkIntegration>;
  triggerIntegrations: ReadonlyArray<TriggerIntegration>;
  medias: ReadonlyArray<ContentMedia>;
  logo: React.ReactElement | null;
  withUserDataPolicy?: boolean;
  customConnectionErrorMessage?: CustomConnectionErrorMessage;
  CreateConnectionComponent?: React.FC<CreateConnectionComponentProps>;
};

export const Apps = [
  CohortApp,
  CohortFormApp,
  DiscordApp,
  FacebookApp,
  GarminConnectApp,
  GoogleFitApp,
  InstagramApp,
  ShopifyApp,
  SpotifyApp,
  StravaApp,
  TalonOneApp,
  TikTokApp,
  TwitterApp,
  TypeformApp,
  YoutubeApp,
] satisfies ReadonlyArray<App>;

export const getMedias = (): ContentMedia[] => {
  return compact(Apps.flatMap((app: App) => app.medias));
};
