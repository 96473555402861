import type {CampaignChallengeWDto} from '@cohort/wallet-schemas/campaign';
import type {RewardBoxWDto} from '@cohort/wallet-schemas/reward';
import {createContext} from 'react';

export type ChallengeContextType = {
  campaign: CampaignChallengeWDto;
  rewardBox: RewardBoxWDto | null;
};

const ChallengeContext = createContext<ChallengeContextType | undefined>(undefined);

export default ChallengeContext;
